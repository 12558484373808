import axios from "axios";

const API = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
	withCredentials: false,
	headers: {
		appId: process.env.REACT_APP_APPID,
		appPassWord: process.env.REACT_APP_APPPASSWORD
	}
});

const APIDagLive = axios.create({
	baseURL: `${process.env.REACT_APP_APIDAGLIVE_URL}`
});

const HookLive = axios.create({ baseURL: process.env.REACT_APP_HOOK_URL });
export { API, APIDagLive, HookLive };
