import React, { Children, cloneElement, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import Error403 from "../../pages/Error403";

export const enum AccessType {
	BUTTON = "button",
	ROUTE = "route",
	OTHER = "other"
}

export const enum AccessKeys {
	DASHBOARD = "dashboard",

	EVENT = "event",

	EVENT_IDENTITY = "event.identity",
	EVENT_IDENTITY_UPDATE = "event.identity.update",
	EVENT_PUBLISH = "event.publish",
	EVENT_UNPUBLISH = "event.unpublish",
	EVENT_NUMBERING_PLAN = "event.numbering_plan",
	EVENT_OPTIONS = "event.options",

	EVENT_RUNS = "event.runs",
	EVENT_RUNS_UPDATE = "event.runs.update",

	EVENT_CONTACTS = "event.contacts",
	EVENT_RIB = "event.rib",
	EVENT_SPONSORS = "event.sponsors",
	EVENT_PREVIEW = "event.preview",

	SUBSCRIPTIONS = "subscriptions",
	SUBSCRIPTIONS_STATUS = "subscriptions.status_subscription",
	SUBSCRIPTIONS_ADD_MANUAL = "subscriptions.add_manual_subscription",
	SUBSCRIPTIONS_COLLECTIVE_MAIL = "subscriptions.collective_mail",
	SUBSCRIPTIONS_MAIL_STATS = "subscriptions.mail_stats",
	SUBSCRIPTIONS_IMPORT = "subscriptions.import_subscription",
	SUBSCRIPTIONS_EXPORT = "subscriptions.export_subscription",
	SUBSCRIPTIONS_MSG_HISTORY = "subscriptions.see_message_history",

	LIVE = "live",
	PERMISSIONS = "permissions",
	STATS = "stats",
	JETCODE = "jetcodes",
	SHOP = "shop"
}

function AccessGuard({
	permissionKey,
	children,
	type
}: {
	permissionKey: string;
	children: React.ReactNode;
	type: string;
}) {
	const { userIsAdmin, permissions } = useContext(UserContext);
	const { slug } = useParams();

	const hasPermission = function (permissionKey: string) {
		if (userIsAdmin) return true;
		if (!slug) return true;
		if (
			permissions &&
			permissions[slug] &&
			permissions[slug][permissionKey] === 1
		)
			return true;
		return false;
	};

	const isLoadingPermissions = function () {
		if (!permissions || Object.keys(permissions).length === 0) return true;
		return false;
	};

	return (
		// We don't want to display anything if we are loading permissions
		// Otherwise, wWe could diplay sensitive data during permissions loading
		isLoadingPermissions() ? (
			<></>
		) : (
			<>
				{type === AccessType.BUTTON &&
					Children.map(children, (child: any, index) =>
						cloneElement(
							child,
							!hasPermission(permissionKey)
								? {
										disabled: true,
										onClick: () => {}
								  }
								: {}
						)
					)}

				{type === AccessType.ROUTE && hasPermission(permissionKey) && children}
				{type === AccessType.ROUTE && !hasPermission(permissionKey) && (
					<Error403 />
				)}
			</>
		)
	);
}

export default AccessGuard;
