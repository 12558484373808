import L from "leaflet";

export const CustomUserMarker = (name: string) => {
	return L.divIcon({
		className: "",
		iconSize: [35, 35],
		iconAnchor: [17.5, 17.5],
		html: `<div style="width:35px;height:35px;border-radius:35px;border-width:2px;borderStyle:solid;display:flex;justify-content:center;align-items:center;background-color:white;border-color:rgb(50,50,50);">
        <p style="color:rgb(50,50,50);font-size:16px;font-weight:bold">${name
					.split(" ")
					.map((item) => item[0])
					.join("")
					.slice(0, 2)}</p>
      </div>`
	});
};

export const CustomStartMarker = () => {
	return L.divIcon({
		className: "",
		iconSize: [35, 35],
		iconAnchor: [17.5, 17.5],
		html: `<div style="width:35px;height:35px;border-radius:35px;border-width:2px;borderStyle:solid;display:flex;justify-content:center;align-items:center;background-color:rgba(0,220,0,0.7);border-color:#07BB00;">
        <p style="color:white;font-size:16px;font-weight:bold">D</p>
      </div>`
	});
};

export const CustomEndMarker = () => {
	return L.divIcon({
		className: "",
		iconSize: [35, 35],
		iconAnchor: [17.5, 17.5],
		html: `<div style="width:35px;height:35px;border-radius:35px;border-width:2px;borderStyle:solid;display:flex;justify-content:center;align-items:center;background-color:rgba(220,0,0,0.7);border-color:#B00300;">
        <p style="color:white;font-size:16px;font-weight:bold">A</p>
      </div>`
	});
};
export const CustomStartEndMarker = () => {
	return L.divIcon({
		className: "",
		iconSize: [35, 35],
		iconAnchor: [17.5, 17.5],
		html: `<div style="width:35px;height:35px;border-radius:35px;border-width:2px;borderStyle:solid;display:flex;justify-content:center;align-items:center;background-color:rgba(255,165,0,0.7);border-color:#FFA500;">
        <p style="color:white;font-size:16px;font-weight:bold">D/A</p>
      </div>`
	});
};
