import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
	unstable_useBlocker,
	useBeforeUnload,
	useParams
} from "react-router-dom";
import { updateFormConfig } from "../../../api/event";
import { getLiveConfig } from "../../../api/live";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import { IRunFormConfig } from "../../../types/EventForms/ISportRun";
import { classNames } from "../../../utils/Classes";
import { JSONParseSafe } from "../../../utils/Json";
import Toast from "../../../utils/Toasts";

const mandatory_fields = [
	"nom",
	"prenom",
	"sexe",
	"dateNaissance",
	"mail",
	"ffa"
];

const SportEventRunFormModal = ({
	idCalendrier,
	runForm,
	closeModal,
	fields,
	refetch,
	handleForm
}: {
	idCalendrier: number;
	runForm: any;
	closeModal: any;
	fields: IRunFormConfig[];
	refetch: any;
	handleForm?: any;
}) => {
	const { slug } = useParams();
	const [isFFAEvent, setIsFFAEvent] = useState<boolean>(false);
	const { lang, unsavedChangesRef } = useContext(AppContext);
	const [FFAInfoMessage, setFFAInfoMessage] = useState<string>(
		" : Le coureur devra renseigner son N° PPS ou son N° de licence FFA"
	);

	const formatedFields = Object.fromEntries(
		fields.map((item) => [
			item.type.nom_champs,
			{
				visible: item.type.visible,
				required: item.type.obligatoire,
				id: item.type.id
			}
		])
	);

	const [groupedFields, setGroupedFields] = useState<any[]>([]);
	const { data: LiveConfig, isLoading: LiveConfigLoading } = useQuery({
		queryKey: ["live_config", slug],
		queryFn: () => getLiveConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	useEffect(() => {
		if (LiveConfig) {
			const isFFA =
				(JSONParseSafe(LiveConfig?.observation)?.CMPCOD || "").length > 0;
			setIsFFAEvent(isFFA);

			const groupNominative = {
				id: "nominative",
				libelle: trad[lang].group_fields_nominative,
				fields: []
			};
			const groupFFA = {
				id: "ffa",
				libelle: trad[lang].group_fields_FFA,
				fields: []
			};
			const otherGroup = {
				id: "others",
				libelle: trad[lang].group_fields_others,
				fields: []
			};
			const groupedFields = [groupNominative, groupFFA, otherGroup];

			for (const field of fields) {
				if (
					field.type.nom_champs === "nom" ||
					field.type.nom_champs === "prenom" ||
					field.type.nom_champs === "sexe" ||
					field.type.nom_champs === "dateNaissance" ||
					field.type.nom_champs === "mail" ||
					field.type.nom_champs === "ville" ||
					field.type.nom_champs === "adresse1" ||
					field.type.nom_champs === "adresse2" ||
					field.type.nom_champs === "codePostal" ||
					field.type.nom_champs === "telephone" ||
					field.type.nom_champs === "telephoneUrgence" ||
					field.type.nom_champs === "nationalite" ||
					field.type.nom_champs === "pays"
				)
					groupNominative.fields.push(field as never);
				else if (
					isFFA &&
					(field.type.nom_champs === "numLicence" ||
						field.type.nom_champs === "certificat" ||
						field.type.nom_champs === "ffa")
				) {
					if (field.type.nom_champs === "certificat")
						field.type.type = "string";
					if (field.type.nom_champs !== "ffa") {
						groupFFA.fields.push(field as never);
					}
				} else otherGroup.fields.push(field as never);

				// Add "(file 📄)" if field.type === "file"
				if (field.type.type === "file") {
					field.type.libelle += "(Fichier 📄)";
				}
			}

			setGroupedFields(groupedFields);
		}
	}, [fields, LiveConfig]);

	const [localForm, setLocalForm] = useState<{
		[key: string]: { visible: boolean; required: boolean; id: number };
	}>({
		...formatedFields,
		...runForm
	});

	const onClickChecboxVisible = (
		key: string,
		value: any,
		checkboxName: string
	) => {
		setLocalForm((old) => ({
			...old,
			[key]: {
				...old[key],
				visible: value,
				required: value == false ? false : old[key].required
			}
		}));

		// If it's a FFA event, certificat and numLicence can't be used at the same time
		if (
			isFFAEvent &&
			(key === "certificat" || key === "numLicence") &&
			value === true
		) {
			const otherKey = key === "certificat" ? "numLicence" : "certificat";
			onClickChecboxVisible(otherKey, false, "visible");
		}

		unsavedChangesRef.current = true;
	};

	const onClickCheckboxRequired = (
		key: string,
		value: any,
		checkboxName: string
	) => {
		setLocalForm((old) => ({
			...old,
			[key]: {
				...old[key],
				required: value,
				visible: value == true ? true : old[key].required
			}
		}));

		// If it's a FFA event, certificat and numLicence can't be used at the same time
		if (
			isFFAEvent &&
			(key === "certificat" || key === "numLicence") &&
			value === true
		) {
			const otherKey = key === "certificat" ? "numLicence" : "certificat";
			onClickChecboxVisible(otherKey, false, "visible");
		}
		unsavedChangesRef.current = true;
	};

	useEffect(() => {
		if (isFFAEvent && localForm) {
			// Update FFA info message
			let tradKey = "infos_ffa_";
			let pps = localForm["certificat"];
			let numLicence = localForm["numLicence"];
			let field = numLicence?.visible ? numLicence : pps;
			tradKey += field === pps ? "ppsandlicence" : "numlicence";
			tradKey += field?.required ? "_required" : "_optional";
			tradKey += "_message";
			setFFAInfoMessage((trad[lang] as any)[tradKey]);
		}
	}, [localForm, isFFAEvent]);

	const validateForm = async () => {
		try {
			if (handleForm) {
				await handleForm({ form: localForm });
			} else {
				await updateFormConfig(idCalendrier, localForm);

				refetch();
				closeModal();

				Toast.success(trad[lang].success_updating_fields);
			}
		} catch (error) {
			Toast.error(trad[lang].error_updating_fields);
		}
	};

	// Unsaved changes ? (Display prompt if true)
	const isPromptConsumedRef = useRef(false);

	unstable_useBlocker((args) => {
		if (unsavedChangesRef.current && isPromptConsumedRef.current === false) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) isPromptConsumedRef.current = true;
			return !userDiscarded;
		}
		return false;
	});

	useBeforeUnload(
		useCallback(
			(e) => {
				if (unsavedChangesRef.current) {
					e.preventDefault();
					return (e.returnValue = "");
				}
			},
			[unsavedChangesRef.current]
		)
	);

	const onClickCloseModal = () => {
		if (unsavedChangesRef.current === true) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) isPromptConsumedRef.current = true;
			if (userDiscarded === false) return;
		}
		closeModal();
	};

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white py-3 xl:w-3/5 2xl:w-3/5">
				<h2 className="mb-3 px-3 text-center text-2xl font-bold">
					{trad[lang].event_run_form_title}
				</h2>

				<div className="mb-5 max-h-[60vh] overflow-y-auto border border-gray-200">
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="sticky top-0 rounded-lg bg-gray-50">
							<tr>
								<th className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
									Type
								</th>
								<th className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
									Visible
								</th>
								<th className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
									Obligatoire
								</th>
							</tr>
						</thead>

						{groupedFields.map(
							(group) =>
								group &&
								group.fields &&
								group.fields.length > 0 && (
									<>
										<tbody className="divide-y divide-gray-200 bg-primary bg-opacity-10">
											<tr className="select-nonealign-top text-sm font-normal text-gray-700">
												<td colSpan={3} className="ml-4 px-4 py-1.5">
													<span className="font-bold">
														{group.libelle}
														{group.id === "ffa" ? " : " : ""}
													</span>
													{group.id === "ffa" && FFAInfoMessage && (
														<span className="font-bold text-orange-500">
															{FFAInfoMessage}
														</span>
													)}
												</td>
											</tr>
										</tbody>
										{group.fields.map((field: any) => (
											<>
												<tbody className="divide-y divide-gray-200 bg-white">
													<tr key={field.id}>
														<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
															{
																(trad[lang] as any)[
																	`event_field_${
																		isFFAEvent &&
																		field.type.nom_champs === "certificat"
																			? "pps"
																			: field.type.nom_champs
																	}_label`
																]
															}
															{field.type.type === "file" &&
																` (${trad[lang].file} 📄)`}
														</td>
														<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
															<input
																type="checkbox"
																checked={
																	localForm[field.type.nom_champs]?.visible
																}
																onChange={(e) =>
																	onClickChecboxVisible(
																		field.type.nom_champs,
																		e.target.checked,
																		"visible"
																	)
																}
																className={classNames(
																	"h-4 w-4 rounded border-gray-300 focus:ring-0",
																	mandatory_fields.includes(
																		field.type.nom_champs
																	)
																		? "text-gray-300"
																		: "text-primary focus:border-primary"
																)}
																disabled={mandatory_fields.includes(
																	field.type.nom_champs
																)}
															/>
														</td>

														<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
															<input
																type="checkbox"
																checked={
																	localForm[field.type.nom_champs]?.required
																}
																onChange={(e) =>
																	onClickCheckboxRequired(
																		field.type.nom_champs,
																		e.target.checked,
																		"required"
																	)
																}
																className={classNames(
																	"h-4 w-4 rounded border-gray-300 focus:ring-0",
																	mandatory_fields.includes(
																		field.type.nom_champs
																	)
																		? "text-gray-300"
																		: "text-primary focus:border-primary"
																)}
																disabled={mandatory_fields.includes(
																	field.type.nom_champs
																)}
															/>
														</td>
													</tr>
												</tbody>
											</>
										))}
									</>
								)
						)}
					</table>
				</div>

				<div className="mt-3 flex flex-row gap-3 px-3">
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
						onClick={onClickCloseModal}
					>
						{trad[lang].close}
					</button>

					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={validateForm}
					>
						{trad[lang].validate}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SportEventRunFormModal;
