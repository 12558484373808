import { FaChartBar } from "react-icons/fa";
import { RiMailSendLine } from "react-icons/ri";
import trad from "../../lang/traduction";
import { ILang } from "../../types/Lang";
import AccessGuard, { AccessKeys, AccessType } from "../navigation/AccessGuard";

function SendMail({
	openMail,
	openCampain,
	setOpenMail,
	setOpenCampain,
	setReceiverMail,
	setReceiverSubcriptionId,
	refetch,
	lang
}: {
	openMail: any;
	openCampain: any;
	setOpenMail: any;
	setOpenCampain: any;
	setReceiverMail: Function;
	setReceiverSubcriptionId: Function;
	lang: ILang;
	refetch: () => void;
}) {
	return (
		<div className="flex gap-3">
			<AccessGuard
				permissionKey={AccessKeys.SUBSCRIPTIONS_COLLECTIVE_MAIL}
				type={AccessType.BUTTON}
			>
				<button
					className="flex max-h-[42px] w-full flex-row items-center justify-center gap-1 rounded-md border border-secondary py-2 px-3 text-secondary duration-150 hover:border-primary hover:text-primary disabled:border-gray-400 disabled:text-gray-400 disabled:opacity-70 sm:w-auto"
					onClick={() => {
						setReceiverMail(null);
						setReceiverSubcriptionId(null);
						setOpenMail(true);
					}}
				>
					<RiMailSendLine size={18} />
					{trad[lang].sendMailAllSubscribers}
				</button>
			</AccessGuard>
			<AccessGuard
				permissionKey={AccessKeys.SUBSCRIPTIONS_MAIL_STATS}
				type={AccessType.BUTTON}
			>
				<button
					className="flex max-h-[42px] flex-row items-center gap-1 rounded-md border border-secondary py-1 px-2 text-secondary duration-150 hover:border-primary hover:text-primary disabled:border-gray-400 disabled:text-gray-400 disabled:opacity-70 md:ml-0"
					onClick={() => {
						setOpenCampain(true);
					}}
				>
					<FaChartBar size={18} />
				</button>
			</AccessGuard>
		</div>
	);
}

export default SendMail;
