import "leaflet/dist/leaflet.css";
import { useRef } from "react";
import {
	AiOutlineClockCircle,
	AiOutlineLoading3Quarters
} from "react-icons/ai";
import { Marker, Popup } from "react-leaflet";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getAppLastPositions, getEventRunList } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import AppMap from "../components/map/AppMap";
import { CustomUserMarker } from "../components/map/CustomMarker";
import Layout from "../components/navigation/Layout";

const LivePositions = () => {
	const { slug } = useParams();
	const mapRef = useRef<any>();

	const { data } = useQuery({
		queryKey: ["event_routes", slug],
		queryFn: () => getEventRunList(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const { data: LastPositions = [] } = useQuery({
		queryKey: ["app_last_positions", data?.id],
		queryFn: () => getAppLastPositions(data?.id),
		refetchOnWindowFocus: false,
		refetchInterval: 10000,
		enabled: !!data?.id
	});

	const recenterOnUser = (lat: number, lng: number) => {
		if (!mapRef?.current) return;

		mapRef.current.panTo([lat, lng]);
	};

	if (!data)
		return <AiOutlineLoading3Quarters size={20} className="animate-spin" />;

	return (
		<Layout active_key="live" forceReduced>
			<div className="relative flex h-screen w-full flex-col items-center gap-5 overflow-y-auto p-5">
				<BreadCrumb
					items={[
						{
							key: "event",
							text: data?.nom,
							to: `/${slug}/event-details`,
							active: false
						},
						{ key: "live_results", to: `/${slug}/live`, active: false },
						{ key: "live_positions", to: "/live", active: true }
					]}
				/>

				<div className="flex w-full flex-1 flex-row gap-5">
					<AppMap id={data.id} mapRef={mapRef}>
						{LastPositions.map((item: any) => (
							<Marker
								position={[item.latitude, item.longitude]}
								icon={CustomUserMarker(item.nom)}
								key={item.id}
								zIndexOffset={10}
							>
								<Popup>
									<p className="font-bold">{item.nom}</p>
									<p className="flex flex-row gap-2">
										<AiOutlineClockCircle size={16} />
										{new Date(item.date).toLocaleString(undefined, {
											day: "2-digit",
											month: "2-digit",
											year: "numeric",
											hour: "2-digit",
											minute: "2-digit",
											second: "2-digit"
										})}
									</p>
								</Popup>
							</Marker>
						))}
					</AppMap>

					<div className="h-full w-1/4">
						<h2 className="mb-2 font-bold italic">Utilisateur actifs</h2>

						<div className="flex h-full max-h-[70vh] flex-col gap-5 overflow-y-auto">
							{LastPositions.map((item: any) => (
								<div
									className="cursor-pointer rounded-md border border-gray-400 p-4 shadow-md transition-colors hover:bg-slate-100"
									onClick={() => recenterOnUser(item.latitude, item.longitude)}
									key={item.id}
								>
									<p className="font-bold">{item.nom}</p>
									<p className="flex flex-row items-center gap-2">
										<AiOutlineClockCircle size={16} />
										{new Date(item.date).toLocaleString(undefined, {
											day: "2-digit",
											month: "2-digit",
											year: "numeric",
											hour: "2-digit",
											minute: "2-digit",
											second: "2-digit"
										})}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>

			<BottomBarNavigation back_to={`/${slug}/event-details`} />
		</Layout>
	);
};

export default LivePositions;
