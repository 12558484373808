import { useContext } from "react";
import trad from "../../lang/traduction";
import { AppContext } from "../../contexts/AppContext";
import { formatDateString } from "../../utils/DateFormater";
import { AiOutlineMan, AiOutlineWoman } from "react-icons/ai";
import { FaTransgender } from "react-icons/fa";

const ManageRunnerResum = ({
	detectionsData,
	data,
	closeModal
}: {
	detectionsData: any;
	data: any;
	closeModal: () => void;
}) => {
	const { lang } = useContext(AppContext);
	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="flex w-11/12 min-w-[300px] max-w-screen-lg flex-col rounded-md border bg-white p-6 md:w-4/5 lg:w-3/5">
				<div className="flex flex-col items-start justify-between rounded-t border-b p-4">
					<span
						className={`text-3xl font-semibold ${
							data.sexe === "Homme"
								? "text-primary"
								: data.sexe === "Femme"
								? "text-pink-400"
								: "text-yellow-400"
						}`}
					>
						{data.name}
					</span>
					<span className="text-xl font-semibold">{data.courseNom}</span>
				</div>
				<div className="max-h-[70vh] overflow-y-auto px-4 pt-3 pb-3">
					<div className="flex flex-col justify-between sm:flex-row">
						<div className="">
							<div>
								<span className="font-mono text-2xl font-semibold text-black">
									{trad[lang].rankingHeaderBib} {data.dossard}
								</span>
							</div>
							<div className="mt-4">
								{trad[lang].rankingHeaderRank}:{" "}
								<span className="font-mono font-bold text-black">
									{data.classGen} / {data.subs}
								</span>
							</div>
							<div>
								{trad[lang].rankingHeaderCategoryRank}:{" "}
								<span className="font-mono font-bold text-black">
									{data.classCat} / {data.subs}
								</span>
							</div>
							<div>
								{trad[lang].rankingHeaderSexRank}:{" "}
								<span className="font-mono font-bold text-black">
									{data.classSx} / {data.subs}
								</span>
							</div>
							<div>
								<span className="font-mono font-bold text-black">
									{trad[lang].time}:{" "}
								</span>
								{data.temps ? data.temps : "N/A"}
							</div>
						</div>
						<div className="mt-8">
							<div className="hidden sm:block">
								<div className="flex flex-row items-center gap-1">
									<span className="font-mono font-bold text-black">
										{trad[lang].sex}:{" "}
									</span>
									{data.sexe === "Homme" ? (
										<div className="flex flex-row items-center gap-1">
											<span>{trad[lang].man}</span>
											<AiOutlineMan size={20} color="rgb(0, 150, 255)" />
										</div>
									) : data.sexe === "Femme" ? (
										<div className="flex flex-row items-center gap-1">
											<span>{trad[lang].woman}</span>
											<AiOutlineWoman size={20} color="rgb(222, 49, 99)" />
										</div>
									) : (
										<div className="flex flex-row items-center gap-1">
											<span>{trad[lang].other}</span>
											<FaTransgender size={20} color="rgb(255, 220, 49)" />
										</div>
									)}
								</div>
							</div>
							<div>
								<span className="font-mono font-bold text-black">
									{trad[lang].category}:{" "}
								</span>
								{data.categorieNom}
							</div>
							{data?.equipe && data.equipe.length > 0 && (
								<div>
									<span className="font-mono font-bold text-black">
										{trad[lang].rankingHeaderTeam}:{" "}
									</span>
									{data.equipe}
								</div>
							)}
							{data.vitesse && (
								<div>
									<span className="font-mono font-bold text-black">
										{trad[lang].speed}:{" "}
									</span>
									{data.vitesse} km/h
								</div>
							)}
							{data.ecart && (
								<div>
									<span className="font-mono font-bold text-black">
										{trad[lang].gap}:{" "}
									</span>
									{data.ecart}
								</div>
							)}
						</div>
					</div>
				</div>
				{detectionsData && detectionsData.length > 0 && (
					<div className="px-4 pt-3 pb-3">
						<span className="font-mono text-2xl">{trad[lang].passageTime}</span>
						<div className="mt-4 flex w-full flex-col gap-4 divide-y">
							{detectionsData.map((detection: any, index: any) => (
								<div
									key={index}
									className="flex w-full flex-row justify-between"
								>
									<p className="w-2/12 px-1 font-mono">{detection.ligne}</p>

									<p className="w-4/12 px-1 font-mono">
										{detection.nomPointage}
									</p>

									<p className="flex w-4/12 flex-row px-1 font-mono">
										{formatDateString(detection.date)}
									</p>
								</div>
							))}
						</div>
					</div>
				)}
				<div className="flex w-full justify-center">
					<button
						type="button"
						className={`rounded-lg border border-primary bg-white px-5 py-2.5 text-center text-sm font-medium text-primary focus:outline-none`}
						onClick={closeModal}
					>
						{trad[lang].close}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ManageRunnerResum;
