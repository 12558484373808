import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useRef,
	useState
} from "react";
import { AiFillPlusCircle, AiOutlineClose } from "react-icons/ai";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { insertDetection } from "../../api/detection";
import { getLiveConfig } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { formatLikeISO } from "../../utils/DateFormater";
import Toast from "../../utils/Toasts";

interface InsertDetectionProps {
	refetch: any;
	credential: string;
	openedInsert: boolean;
	setOpenedInsert: Dispatch<SetStateAction<boolean>>;
}

const InsertDetection = ({
	refetch,
	credential,
	openedInsert,
	setOpenedInsert
}: InsertDetectionProps) => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const [detection, setDetection] = useState({
		line: "",
		bib: "",
		date: formatLikeISO(new Date())
	});

	const { data: LiveConfig, isLoading: LiveConfigLoading } = useQuery({
		queryKey: ["live_config", slug],
		queryFn: () => getLiveConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const closeDetection = () => {
		setOpenedInsert(false);

		setDetection({
			line: "",
			bib: "",
			date: formatLikeISO(new Date())
		});
	};

	const handleForm = (key: string, value: string) =>
		setDetection((old) => ({ ...old, [key]: value }));

	const submitDetection = async () => {
		try {
			const day = detection.date.slice(8, 10);
			const month = detection.date.slice(5, 7);
			const year = detection.date.slice(0, 4);
			const time = detection.date.slice(11);

			const trame = `000001;99;${detection.bib};${time};${day}-${month}-${year};Insert Chronometrage`;

			await insertDetection(detection.line, trame, credential);

			localStorage.setItem("line", detection.line);
			setTimeout(async () => {
				await refetch();
			}, 500);

			closeDetection();

			Toast.success(trad[lang].insertSuccess);
		} catch (error) {
			Toast.error(trad[lang].insertErrorDetection);
		}
	};

	const lines = LiveConfig?.calendrier_child
		?.map((item: any) => item.pointages)
		.flat(1);

	const toggleOpen = () => {
		const uniqueEnds = [
			...new Set(
				lines
					.filter((item: any) => item.type == 3)
					.map((detector: any) => detector.ligne)
			)
		];
		const uniqueLines = [
			...new Set(lines.map((detector: any) => detector.ligne))
		];
		const oldLine = localStorage.getItem("line");

		setDetection({
			line: `${
				oldLine && uniqueLines.includes(parseInt(oldLine))
					? oldLine
					: `${uniqueEnds[0]}`
			}`,
			bib: "",
			date: formatLikeISO(new Date())
		});

		setOpenedInsert(true);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		const activeElement = document.activeElement;
		const isInputFocused =
			activeElement &&
			(activeElement.tagName === "INPUT" ||
				activeElement.tagName === "TEXTAREA");
		if (isInputFocused) return;

		const keyNumber = parseInt(e.key);

		if (isNaN(keyNumber)) return;

		toggleOpen();
	};

	const handleKeyDownEsc = (e: KeyboardEvent) => {
		if (e.key === "Escape") {
			setDetection({
				line: "",
				bib: "",
				date: formatLikeISO(new Date())
			});

			setOpenedInsert(false);
		}
	};

	const handleKeyDownEnter = async (e: KeyboardEvent) => {
		if (e.key === "Enter") {
			await submitDetection();
		}
	};

	useEffect(() => {
		if (!openedInsert) {
			document.addEventListener("keydown", handleKeyDown);
		} else {
			document.addEventListener("keydown", handleKeyDownEsc);
			document.addEventListener("keydown", handleKeyDownEnter);
		}

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
			document.removeEventListener("keydown", handleKeyDownEsc);
			document.removeEventListener("keydown", handleKeyDownEnter);
		};
	}, [openedInsert, LiveConfig]);

	useEffect(() => {
		if (openedInsert) {
			document.addEventListener("keydown", handleKeyDownEnter);
		}

		return () => {
			document.removeEventListener("keydown", handleKeyDownEnter);
		};
	}, [openedInsert, detection]);

	const inputRefs = useRef<
		(HTMLInputElement | HTMLButtonElement | HTMLSelectElement)[]
	>([]);

	const handleTabKeyDown = (e: React.KeyboardEvent, index: number) => {
		if (e.key === "Tab") {
			e.preventDefault();

			const nextIndex = (index + 1) % inputRefs.current.length;
			inputRefs.current[nextIndex]?.focus();
		}
	};

	return (
		<>
			<div
				className="flex h-10 w-10 cursor-pointer items-center justify-center gap-2 rounded border bg-primary px-2 text-white shadow-md transition-colors hover:bg-primarydark"
				onClick={toggleOpen}
			>
				<AiFillPlusCircle size={20} />
			</div>

			{openedInsert && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
					<div className="max-w-screen-lg rounded-md border bg-white">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h3 className="text-xl font-semibold text-gray-900">
								{trad[lang].add_detection}
							</h3>

							<button
								type="button"
								className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
								onClick={() => setOpenedInsert(false)}
							>
								<AiOutlineClose size={16} />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="flex gap-6 p-6">
							<div>
								<label>{trad[lang].number_bib}</label>
								<input
									type="number"
									name="bib"
									id="bib"
									className="block rounded-md border border-gray-300 py-2 text-sm focus:ring-0"
									onChange={(e) => handleForm("bib", e.target.value)}
									placeholder={trad[lang].number_bib}
									value={detection.bib}
									ref={(el) => {
										if (el) inputRefs.current[0] = el;
									}}
									onKeyDown={(e) => handleTabKeyDown(e, 0)}
									autoFocus
								/>
							</div>

							<div>
								<label>{trad[lang].time_detection}</label>
								<input
									type="datetime-local"
									name="date"
									id="date"
									className="block rounded-md border border-gray-300 py-2 text-sm focus:ring-0"
									onChange={(e) => handleForm("date", e.target.value)}
									value={detection.date}
									step={0.01}
									ref={(el) => {
										if (el) inputRefs.current[1] = el;
									}}
									onKeyDown={(e) => handleTabKeyDown(e, 1)}
								/>
							</div>

							<div>
								<label>{trad[lang].number_line}</label>
								<select
									name="line"
									id="line"
									onKeyDown={(e) => handleTabKeyDown(e, 2)}
									className="block min-w-[150px] rounded-md border border-gray-300 py-2 text-sm focus:ring-0"
									onChange={(e) => handleForm("line", e.target.value)}
									ref={(el) => {
										if (el) inputRefs.current[2] = el;
									}}
									value={detection.line}
								>
									{[...new Set(lines.map((item: any) => item.ligne))]
										.sort((a: any, b: any) => {
											if (a < b) return 1;
											if (a > b) return -1;
											return 0;
										})
										.map((ligne: any, index) => (
											<option key={index} value={ligne}>
												{ligne}
											</option>
										))}
								</select>
							</div>
						</div>

						<div className="flex flex-row-reverse items-center gap-2 rounded-b border-t border-gray-200 p-4">
							<button
								type="button"
								className="rounded-lg bg-primary px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300"
								onClick={submitDetection}
								ref={(el) => {
									if (el) inputRefs.current[3] = el;
								}}
								onKeyDown={(e) => handleTabKeyDown(e, 3)}
							>
								{trad[lang].add_detection}
							</button>
							<button
								type="button"
								className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
								onClick={closeDetection}
							>
								{trad[lang].cancel}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default InsertDetection;
