import { Fragment, useEffect, useMemo } from "react";
import { AiOutlineAim } from "react-icons/ai";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import { useQuery } from "react-query";
import { getAppInfos } from "../../api/event";
import {
	CustomEndMarker,
	CustomStartEndMarker,
	CustomStartMarker
} from "./CustomMarker";

const AppMap = ({
	id,
	mapRef,
	children
}: {
	id: number;
	mapRef: any;
	children?: React.ReactNode;
}) => {
	const { data: AppInfos } = useQuery({
		queryKey: ["app_infos", id],
		queryFn: () => getAppInfos(id),
		refetchOnWindowFocus: false,
		enabled: !!id
	});

	const polylines = useMemo(() => {
		if (!AppInfos?.traces?.length) return [];

		return AppInfos.traces;
	}, [AppInfos?.traces]);

	const recenter = () => {
		if (mapRef?.current && AppInfos?.traces?.length > 0) {
			const bounds: any = [];

			AppInfos.traces.forEach((trace: any) => {
				trace.positions.forEach((position: any) => {
					bounds.push([position.latitude, position.longitude]);
				});
			});

			mapRef?.current.fitBounds(bounds);
		}
	};

	useEffect(() => {
		recenter();
	}, [mapRef?.current, AppInfos?.traces]);

	return (
		<div className="h-full w-full overflow-hidden rounded-md">
			<MapContainer
				center={[45.75, 4.85]}
				zoom={13}
				scrollWheelZoom={false}
				className="relative h-full w-full"
				ref={mapRef}
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>

				<div
					className="absolute top-2 right-2 z-[400] flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border-2 border-black/50 bg-white transition-colors hover:bg-slate-200"
					onClick={recenter}
				>
					<AiOutlineAim size={17} />
				</div>

				{polylines.map((item: any, index: number) => (
					<Fragment key={index}>
						<Polyline
							positions={item.positions.map((item: any) => [
								item.latitude,
								item.longitude
							])}
							color={item.couleur || "#000000"}
						/>

						{item.positions[0].latitude ==
							item.positions[item.positions.length - 1].latitude &&
						item.positions[0].longitude ==
							item.positions[item.positions.length - 1].longitude ? (
							<Marker
								position={[
									item.positions[0].latitude,
									item.positions[0].longitude
								]}
								title={`${item.name} - Départ & Arrivée`}
								icon={CustomStartEndMarker()}
							/>
						) : (
							<>
								<Marker
									position={[
										item.positions[0].latitude,
										item.positions[0].longitude
									]}
									title={`${item.name} - Départ`}
									icon={CustomStartMarker()}
								/>

								<Marker
									position={[
										item.positions[item.positions.length - 1].latitude,
										item.positions[item.positions.length - 1].longitude
									]}
									title={`${item.name} - Arrivée`}
									icon={CustomEndMarker()}
								/>
							</>
						)}
					</Fragment>
				))}

				{children}
			</MapContainer>
		</div>
	);
};

export default AppMap;
