import { useContext, useState } from "react";
import {
	AiOutlineExclamationCircle,
	AiOutlineLoading3Quarters
} from "react-icons/ai";
import { FaPlusCircle } from "react-icons/fa";
import { MdOutlineDangerous } from "react-icons/md";
import { useParams } from "react-router-dom";
import { getDetectionsForBib } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";
import trad from "../../lang/traduction";

const sexe = ["", "H", "F", "A"];
const sexColors = {
	1: "text-blue-600",
	2: "text-pink-600",
	3: "text-yellow-500"
};

const ManageRunnerResults = ({
	onRunnerClick
}: {
	onRunnerClick: (data: any, detectionsData: any) => void;
}) => {
	const { lang } = useContext(AppContext);
	const {
		LiveData,
		selectedRunnerIndex,
		setSelectedRunnerIndex,
		LiveDataLoading
	} = useContext(ManageRunnerContext);
	const [openModal, setOpenModal] = useState(false);
	const [bib, setBib] = useState(0);
	const [data, setData] = useState({
		name: "",
		dossard: "",
		classGen: "",
		classCat: "",
		classSx: "",
		subs: LiveData.subs,
		temps: "",
		detections: "",
		sexe: "",
		categorieNom: "",
		equipe: "",
		vitesse: "",
		ecart: "",
		courseNom: ""
	});
	const [detectionsData, setDetectionsData] = useState<[]>([]);
	const { slug } = useParams();
	if (LiveDataLoading) {
		return (
			<div className="flex flex-row justify-center py-5">
				<AiOutlineLoading3Quarters size={45} className="ml-2 animate-spin" />
			</div>
		);
	}
	const computeStyle = (isSelected: boolean, status: number, sex: number) => {
		const color = sexColors[sex as keyof typeof sexColors];

		if (status == 3) {
			return `${
				isSelected ? "bg-blue-200" : "bg-red-300"
			} text-black line-through`;
		}

		if (status == 2) {
			return `${isSelected ? "bg-blue-200" : "bg-yellow-300"} text-black`;
		}

		if (status > 0) {
			return `${isSelected ? "bg-blue-200" : "bg-gray-300"} text-black`;
		}

		return `${
			isSelected ? "bg-blue-200" : "odd:bg-slate-200 even:bg-slate-100"
		} ${color}`;
	};
	const { LiveConfig } = useContext(ManageRunnerContext);

	const runnerDetections = async (item: any) => {
		try {
			const detections = await getDetectionsForBib(
				slug as string,
				item.e,
				LiveConfig.id
			);
			setDetectionsData(detections);

			const updatedData = {
				name: String(item.d || ""),
				dossard: String(item.e || ""),
				classGen: String(item.a || "non classé"),
				classCat: String(item.b || "non classé"),
				classSx: String(item.c || "non classé"),
				subs: LiveData.subs,
				temps: String(item.f || ""),
				detections: String(item.g || ""),
				sexe: item.h === 2 ? "Femme" : item.h === 1 ? "Homme" : "Autre",
				categorieNom: String(item.j || ""),
				equipe: String(item.m || ""),
				vitesse: String(item.n || ""),
				ecart: String(item.o || ""),
				courseNom: String(item.y || "")
			};
			setData(updatedData);
			onRunnerClick(updatedData, detections);
		} catch (error) {
			console.error("Erreur lors de la récupération des détections:", error);
		}
	};
	const handleClick = async (item: any, index: number) => {
		setSelectedRunnerIndex(index);
		setBib(item.e as number);

		const detections = await getDetectionsForBib(
			slug as string,
			item.e,
			LiveConfig.id
		);

		const updatedData = {
			name: String(item.d || ""),
			dossard: String(item.e || ""),
			classGen: String(item.a || "non classé"),
			classCat: String(item.b || "non classé"),
			classSx: String(item.c || "non classé"),
			subs: LiveData.subs,
			temps: String(item.f || ""),
			detections: String(item.g || ""),
			sexe: item.h === 1 ? "Homme" : item.h === 2 ? "Femme" : "Autre",
			categorieNom: String(item.j || ""),
			equipe: String(item.m || ""),
			vitesse: String(item.n || ""),
			ecart: String(item.o || ""),
			courseNom: String(item.y || "")
		};

		setData(updatedData);
		setDetectionsData(detections);
		await runnerDetections(item);
		onRunnerClick(updatedData, detections);
	};
	return (
		<>
			{LiveData.result.map((item, index: number) => (
				<div
					key={`${item.e}_${item.k}`}
					className={`flex-start flex flex-row items-center text-sm ${computeStyle(
						selectedRunnerIndex == index,
						item.x || 0,
						item.h || 0
					)}`}
					onClick={() => setSelectedRunnerIndex(index)}
				>
					<p className="lineClamp1 w-[4%] px-2 text-left font-mono">
						{item.f ? item.a : ""}
					</p>
					<p className="lineClamp1 flex w-[15%] flex-row px-2 font-mono">
						{item.d}
					</p>
					<p className="lineClamp1 w-[4%] px-2 text-right font-mono">
						{item.e}
					</p>
					<p className="flex w-[10%] flex-row items-center gap-1 px-2 font-mono">
						{item.x == 3 && (
							<>
								<MdOutlineDangerous color="red" /> Disqualifié
							</>
						)}

						{item.x == 2 && (
							<>
								<AiOutlineExclamationCircle color="red" />
								{item.f ? item.f : "Abandon"}
							</>
						)}

						{item.x == 1 && <>{trad[lang].notStarting}</>}

						{item.x == 0 && <> {item.f ? item.f : "En attente"}</>}
					</p>
					<p className="lineClamp1 w-[8%] px-2 text-left font-mono">{`${
						item.c ? item.c : ""
					} ${item.j ? item.j : ""}`}</p>
					<p className="lineClamp1 w-[8%] px-2 text-left font-mono">{`${
						item.b ? item.b : ""
					} ${item.h ? sexe[item.h] : ""}`}</p>
					<p className="lineClamp1 w-[29%] px-2 font-mono">{item.y}</p>
					<p className="lineClamp1 w-[15%] px-2 font-mono">
						{item.m ? item.m : ""}
					</p>
					<div
						className="lineClamp1 flex w-[3%] cursor-pointer flex-row items-center justify-center"
						onClick={() => (handleClick(item, index), setOpenModal(true))}
					>
						<div>{item.e ? <FaPlusCircle /> : ""}</div>
					</div>
				</div>
			))}
		</>
	);
};

export default ManageRunnerResults;
