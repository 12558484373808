import { useContext } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { AppContext } from "../../contexts/AppContext";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";
import trad from "../../lang/traduction";

const headers = [
	{
		key: "rankingHeaderRank",
		sort: "a",
		classes:
			"flex w-[4%] cursor-pointer flex-row items-center gap-1 px-2 font-mono justify-end select-none",
		sorted: true
	},
	{
		key: "rankingHeaderName",
		sort: "d",
		classes:
			"flex w-[15%] cursor-pointer flex-row items-center gap-1 px-2 font-mono select-none",
		sorted: true
	},
	{
		key: "rankingHeaderBib",
		sort: "e",
		classes:
			"flex w-[4%] cursor-pointer flex-row items-center gap-1 px-2 font-mono justify-end select-none",
		sorted: true
	},
	{
		key: "rankingHeaderTime",
		sort: "fx",
		classes:
			"flex w-[10%] cursor-pointer flex-row items-center gap-1 px-2 font-mono select-none",
		sorted: true
	},
	{
		key: "rankingHeaderCategoryRank",
		sort: "c",
		classes:
			"flex w-[8%] cursor-pointer flex-row items-center gap-1 px-2 font-mono justify-end select-none",
		sorted: true
	},
	{
		key: "rankingHeaderSexRank",
		sort: "b",
		classes:
			"flex w-[8%] cursor-pointer flex-row items-center gap-1 px-2 font-mono justify-end select-none",
		sorted: true
	},
	{
		key: "rankingHeaderRun",
		sort: "z",
		classes:
			"flex w-[29%] flex-row items-center gap-1 px-2 font-mono select-none",
		sorted: false
	},
	{
		key: "rankingHeaderTeam",
		sort: "l",
		classes:
			"flex w-[15%] cursor-pointer flex-row items-center gap-1 px-2 font-mono select-none",
		sorted: true
	},
	{
		key: "infos",
		sort: "",
		classes:
			"flex w-[3%] cursor-pointer flex-row items-center gap-1 px-2 font-mono",
		sorted: false
	}
];

const ManageRunnerHeader = () => {
	const { lang } = useContext(AppContext);
	const { order, handleChangeOrder } = useContext(ManageRunnerContext);

	const handleSort = (key: string) => handleChangeOrder(key);

	return (
		<div className="sticky top-[92px] left-0 right-0 flex w-full flex-row bg-slate-400 text-sm">
			{headers.map((item) => (
				<p
					className={item.classes}
					onClick={() => {
						if (item.sorted) {
							handleSort(item.sort);
						}
					}}
					key={item.key}
				>
					{(trad as any)[lang][item.key]}

					{order.column == item.sort && (
						<>
							{order.order == 1 ? (
								<AiOutlineCaretUp size={12} />
							) : (
								<AiOutlineCaretDown size={12} />
							)}
						</>
					)}
				</p>
			))}
		</div>
	);
};

export default ManageRunnerHeader;
