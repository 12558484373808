import { useContext, useState } from "react";
import { AiOutlineFilter } from "react-icons/ai";
import { AppContext } from "../../contexts/AppContext";
import { useOutsideClick } from "../../hooks/useOutsideClick";

const RegistrationsTableHeaderFilter = ({ item }: { item: any }) => {
	const { setSubscriptionsFilter, subscriptionsFilters } =
		useContext(AppContext);
	const { ref, isComponentVisible, setIsComponentVisible } =
		useOutsideClick(false);
	const [selectedValue, setSelectedValue] = useState(
		subscriptionsFilters[item.key as keyof typeof subscriptionsFilters] || ""
	);

	const toggleFilter = () => setIsComponentVisible((old) => !old);

	const handleChange = (key: string, value: string) => {
		setSelectedValue(value);
		setSubscriptionsFilter((old) => ({ ...old, [key]: value }));
		setIsComponentVisible(false);
	};

	return (
		<div className="relative ml-auto hidden lg:block" ref={ref}>
			<div
				className="flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-md border bg-white hover:shadow-lg"
				onClick={toggleFilter}
			>
				<AiOutlineFilter color="#404040" size={20} />
			</div>

			{isComponentVisible && (
				<div className="absolute top-8 right-[-20px] z-30 rounded-md border bg-white p-2 shadow-md">
					{item.list ? (
						<ul className="max-h-40 w-full overflow-y-auto">
							{item.list.map((option: any) => (
								<li
									key={option.value}
									onClick={() =>
										handleChange(item.key, option.value.toString())
									}
									className={`w-full cursor-pointer p-2 hover:bg-gray-100 ${
										selectedValue === option.value ? "bg-gray-200" : ""
									}`}
								>
									<span className="block truncate">{option.label}</span>
								</li>
							))}
						</ul>
					) : (
						<input
							type="text"
							name={item.key}
							id={item.key}
							value={
								subscriptionsFilters[
									item.key as keyof typeof subscriptionsFilters
								]
							}
							onChange={(e) => handleChange(item.key, e.target.value)}
							className="appearance-none rounded-md border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default RegistrationsTableHeaderFilter;
