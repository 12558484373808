import { useContext, useEffect, useRef, useState } from "react";
import {
	AiFillPlayCircle,
	AiOutlineDownload,
	AiOutlineLoading3Quarters
} from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import { exportPdf, getExportElogicaResults } from "../../api/event";
import { updateRunnerStatus } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";
import trad from "../../lang/traduction";
import { JSONParseSafe } from "../../utils/Json";
import Modal from "../common/Modal";
import InsertDetection from "../detections/InsertDetection";
import CreateRunnerModal from "./CreateRunnerModal";
import { deleteOneSub } from "../../api/admin";
import Toast from "../../utils/Toasts";

const rankingExportOptions = [
	{ id: "classement", title: "Général" },
	{ id: "classementSex", title: "Sexe" },
	{ id: "classementCategorie", title: "Catégorie" }
];

const ManageRunnerMenu = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const {
		selectedRunner,
		run,
		debouncedSearch,
		order,
		source,
		handleChangeRun,
		LiveConfig,
		handleChangeSource,
		Points,
		search,
		finishedRace,
		statutCourse,
		setFinishedRace,
		handleChangeSearch,
		setOpenedDetections,
		setOpenedSubscription,
		setOpenedReset,
		handleChangeStatus,
		LiveDataRefetch,
		LiveData
	} = useContext(ManageRunnerContext);
	const [openedMenu, setOpenedMenu] = useState(false);
	const [openedExportMenu, setOpenedExportMenu] = useState(false);
	const [selectedExportOption, setSelectedExportOption] =
		useState("classement");
	const [selectedExportValue, setSelectedExportValue] = useState(0);
	const [openedExportOptions, setOpenedExportOptions] = useState(false);
	const [adminOption, setAdminOption] = useState(false);
	const [loading, setLoading] = useState(false);
	const [newStatus, setNewStatus] = useState(-1);
	const [openedInsert, setOpenedInsert] = useState(false);
	const [sexPageBreaker, setSexPageBreaker] = useState(false);
	const [allChecked, setAllChecked] = useState(true);
	const [openDelete, setOpenDetele] = useState(false);
	const [checkedState, setCheckedState] = useState(
		new Array(LiveConfig?.calendrier_child?.length).fill(false)
	);
	const [raceIds, setRaceIds] = useState([]);
	const [raceName, setRaceName] = useState<string>(slug as string);
	const [openedSubscriptionWithoutId, setOpenedSubscriptionWithoutId] =
		useState(false);
	const ref = useRef(null);
	const [isFFAEvent, setIsFFAEvent] = useState(false);
	const [isLoadingFFAExport, setIsLoadingFFAExport] = useState(false);
	useOnClickOutside(ref, () => setOpenedMenu(false));
	useEffect(() => {
		const json = JSONParseSafe(LiveConfig?.observation);
		setIsFFAEvent(json?.CMPCOD && json?.CMPDATE);
	}, [LiveConfig]);

	const exportPDF = async () => {
		try {
			setLoading(true);
			await exportPdf(
				slug as string,
				selectedExportOption,
				1,
				adminOption,
				sexPageBreaker,
				raceIds as [],
				raceName
			);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	};

	const openSubscription = (idInscription: number) => {
		setOpenedSubscription(idInscription);
		setOpenedMenu(false);
	};

	const openSubscriptionWithoutId = () => {
		setOpenedSubscriptionWithoutId(true);
		setOpenedMenu(false);
	};

	const openDetections = (dossard: number) => {
		setOpenedDetections(dossard);
		setOpenedMenu(false);
	};

	const openReset = (dossard: number) => {
		setOpenedReset(dossard);
		setOpenedMenu(false);
	};

	const modalMessage = {
		0: trad[lang].updateRunnerStatusStarting,
		1: trad[lang].updateRunnerStatusNotStarting,
		2: trad[lang].updateRunnerStatusSurrended,
		3: trad[lang].updateRunnerStatusDisqualified
	};

	const handleUpdateStatus = async () => {
		try {
			if (!slug) return;

			await updateRunnerStatus(slug, selectedRunner.k, newStatus);

			setNewStatus(-1);
		} catch (error) {
			console.error(error);
		}
	};

	const handleCheckboxChange = (position: any) => {
		const updatedCheckedState = checkedState.map((item, index) =>
			index === position ? !item : item
		);

		const newRaceIds = LiveConfig?.calendrier_child
			?.filter((_: any, index: any) => updatedCheckedState[index])
			.map((item: any) => item.id);
		const newRaceName = LiveConfig?.calendrier_child
			?.filter((_: any, index: any) => updatedCheckedState[index])
			.map((item: any) => item.nom);

		setCheckedState(updatedCheckedState);
		setRaceIds(newRaceIds);
		setRaceName(
			newRaceIds.length == 1
				? newRaceName[0]
				: newRaceIds.length == 0
				? (slug as string)
				: (slug as string)
		);
		setAllChecked(updatedCheckedState.every((state) => !state));
	};
	const handleAllCheckboxChange = () => {
		const newAllChecked = !allChecked;
		setAllChecked(newAllChecked);
		const newCheckedState = new Array(
			LiveConfig?.calendrier_child?.length
		).fill(!newAllChecked);
		setCheckedState(newCheckedState);
		setRaceIds(
			newAllChecked
				? []
				: LiveConfig?.calendrier_child?.map((item: any) => item.id)
		);
		setRaceName(slug as string);
	};

	useEffect(() => {
		if (allChecked) {
			setCheckedState(
				new Array(LiveConfig?.calendrier_child?.length).fill(false)
			);
			setRaceIds([]);
		}
	}, [allChecked, LiveConfig?.calendrier_child?.length]);

	const handleClick = (divId: any) => {
		handleChangeStatus(divId);
		setFinishedRace("false");
	};

	const handleClickAdmin = (_divId: any) => {
		setFinishedRace("true");
		handleChangeStatus("");
	};

	const totalSubscriber = LiveData?.subs;

	const handleExportLogica = async () => {
		try {
			setIsLoadingFFAExport(true);
			const { data, headers } = await getExportElogicaResults(slug as string);

			const blob = new Blob([data], {
				type: "application/octet-stream"
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.download = `${
				LiveConfig.nom
					? LiveConfig.nom + "_Resultats_FFA_e-logica"
					: "Resultats e-logica"
			}.gz`;
			document.body.appendChild(link);
			link.click();

			URL.revokeObjectURL(url);
		} catch (error) {
		} finally {
			setIsLoadingFFAExport(false);
		}
	};

	const deleteSubscription = async (inscriptionId: any) => {
		try {
			await deleteOneSub(inscriptionId);
			await LiveDataRefetch();
			setOpenDetele(false);
			Toast.success(trad[lang].deletionSuccess);
		} catch {
			Toast.error(trad[lang].deletionError);
		}
	};
	return (
		<>
			<div className="sticky top-0 z-10">
				<div className="flex w-full flex-row items-center gap-5 bg-white p-5 shadow-md">
					<div ref={ref}>
						<button
							className="h-10 rounded bg-primary px-2 text-white shadow-md transition-colors hover:bg-primarymedium"
							onClick={() => setOpenedMenu(!openedMenu)}
						>
							Action
						</button>
						{openedMenu && (
							<div className="absolute top-16 left-5 flex flex-col rounded bg-white py-2 shadow-md">
								{selectedRunner?.e && (
									<>
										<h3 className="px-2 pt-2 text-sm text-gray-600">{`${trad[lang].bib} ${selectedRunner.e} - ${selectedRunner.d}`}</h3>

										<div className="flex w-full flex-col border-b border-gray-400">
											<button
												className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
												onClick={() => openSubscription(selectedRunner.k)}
											>
												{trad[lang].editRunner}
											</button>
											<button
												className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
												onClick={() => openSubscriptionWithoutId()}
											>
												Ajouter une inscription
											</button>
											<button
												className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
												onClick={() => openDetections(selectedRunner.e)}
											>
												{trad[lang].manageDetections}
											</button>
											<button
												className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
												onClick={() => openReset(selectedRunner.e)}
											>
												{trad[lang].resetOnRunner}
											</button>
											<button
												className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
												onClick={() => setOpenDetele(true)}
											>
												Supprimer l'inscription
											</button>
										</div>
										<div className="flex w-full flex-col">
											{selectedRunner.x != 0 && (
												<button
													className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
													onClick={() => setNewStatus(0)}
												>
													{trad[lang].setRunnerStarting}
												</button>
											)}
											{selectedRunner.x != 1 && (
												<button
													className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
													onClick={() => setNewStatus(1)}
												>
													{trad[lang].setRunnerNotStarting}
												</button>
											)}
											{selectedRunner.x != 2 && (
												<button
													className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
													onClick={() => setNewStatus(2)}
												>
													{trad[lang].setRunnerSurrended}
												</button>
											)}
											{selectedRunner.x != 3 && (
												<button
													className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-200"
													onClick={() => setNewStatus(3)}
												>
													{trad[lang].setRunnerDisqualified}
												</button>
											)}
										</div>
									</>
								)}
							</div>
						)}
					</div>
					<button
						className="flex h-10 w-10 items-center justify-center rounded-md border bg-primary px-2 text-white shadow-md transition-colors hover:bg-primarymedium focus:outline-none"
						onClick={() => {
							setOpenedExportMenu(!openedExportMenu);
							!openedExportMenu && setOpenedExportOptions(false);
						}}
					>
						<AiOutlineDownload size={25} />
					</button>
					{isFFAEvent && (
						<button
							className={`flex h-10 items-center justify-center rounded-md border ${
								isLoadingFFAExport
									? "bg-orange-400 hover:bg-orange-500"
									: "bg-primary hover:bg-primarymedium"
							} px-2 text-white shadow-md transition-colors focus:outline-none`}
							onClick={() => {
								handleExportLogica();
							}}
						>
							{isLoadingFFAExport ? (
								<>
									<AiOutlineLoading3Quarters
										size={20}
										className="mr-1 animate-spin"
									/>
									FFA
								</>
							) : (
								<>
									<AiOutlineDownload size={25} />
									FFA
								</>
							)}
						</button>
					)}
					<InsertDetection
						openedInsert={openedInsert}
						setOpenedInsert={setOpenedInsert}
						refetch={LiveDataRefetch}
						credential={LiveConfig?.credential}
					/>

					<Link
						to={`/${slug}/live-positions`}
						className="mr-auto flex h-10 cursor-pointer items-center justify-center gap-2 rounded border border-primary bg-white py-4 px-2 text-primary shadow-md transition-transform hover:scale-105"
					>
						<AiFillPlayCircle size={20} className="text-primary" />
						<p>Live</p>
					</Link>
					{/* <div
						className="mr-auto flex cursor-pointer  gap-2 text-primary"
						onClick={() => handleClickAdmin("0")}
					>
						{" "}
						<span className="font-bold text-black">{LiveData.ranked}</span>
						<span>ARR</span> <span>/</span>{" "}
						<span className="font-bold text-black">{LiveData.subs}</span>
						<span>INS</span>
					</div> */}
					<div className="hidden sm:block sm:w-full sm:text-center">
						<p className="text-2xl">
							{LiveConfig?.calendrier_child?.find((item: any) => item.id == run)
								?.nom
								? LiveConfig?.calendrier_child?.find(
										(item: any) => item.id == run
								  )?.nom
								: "Toutes les courses"}
						</p>
					</div>
					<div className="sicky top-[500px] flex gap-4">
						<div
							className={`flex cursor-pointer flex-col items-center justify-center gap-1 rounded p-2 text-[0.5rem] hover:bg-blue-100 lg:text-xs ${
								statutCourse === "" && finishedRace === "false"
									? "bg-blue-100"
									: ""
							}`}
							onClick={() => handleClick("")}
						>
							<span className="text-[0.5rem] lg:text-xs">
								<strong>{totalSubscriber || 0}</strong>
							</span>{" "}
							<span>{trad[lang].SUB}</span>
						</div>
						<div
							className={`flex cursor-pointer flex-col items-center justify-center gap-1 rounded p-2 text-[0.5rem] hover:bg-green-300 lg:text-xs ${
								statutCourse === "" && finishedRace === "true"
									? "bg-green-300"
									: ""
							}`}
							onClick={() => handleClickAdmin("0")}
						>
							<span className="text-[0.5rem] lg:text-xs">
								<strong>{LiveData?.ranked || 0}</strong>
							</span>{" "}
							<span>{trad[lang].ARR}</span>
						</div>
						<div
							className={`flex cursor-pointer flex-col items-center justify-center gap-1 rounded p-2 text-[0.5rem] hover:bg-gray-300 lg:text-xs ${
								statutCourse === "1" ? "bg-gray-300" : ""
							}`}
							onClick={() => handleClick("1")}
						>
							<span className="text-[0.5rem] lg:text-xs">
								<strong>{LiveData?.nostarter || 0}</strong>
							</span>{" "}
							<span>{trad[lang].DNS}</span>
						</div>
						<div
							className={`flex cursor-pointer flex-col items-center justify-center gap-1 rounded p-2 text-[0.5rem] hover:bg-yellow-200 lg:text-xs ${
								statutCourse === "2" ? "bg-yellow-200" : ""
							}`}
							onClick={() => handleClick("2")}
						>
							<span className="text-[0.5rem] lg:text-xs">
								<strong>{LiveData?.surrended || 0}</strong>
							</span>{" "}
							<span>{trad[lang].DNF}</span>
						</div>
						<div
							className={`flex cursor-pointer flex-col items-center justify-center gap-1 rounded p-2 text-[0.5rem] hover:bg-red-300 lg:text-xs ${
								statutCourse === "3" ? "bg-red-300" : ""
							}`}
							onClick={() => handleClick("3")}
						>
							<span className="text-[0.5rem] lg:text-xs">
								<strong>{LiveData?.disqualified || 0}</strong>
							</span>{" "}
							<span>{trad[lang].DQ}</span>
						</div>
					</div>
					<div>
						{openedExportMenu && (
							<Modal
								closeModal={() => setOpenedExportMenu(!openedExportMenu)}
								button={loading ? false : true}
								titleButton="Générer"
								onClickButton={() => exportPDF()}
								cancelBtn={loading ? false : true}
								rounded
								titleCancelButton="Annuler"
								onClickCancelButton={() =>
									setOpenedExportMenu(!openedExportMenu)
								}
								style={{ width: "auto", height: "auto", minWidth: "600px" }}
								children={
									loading ? (
										<AiOutlineLoading3Quarters
											size={45}
											className="ml-2 animate-spin"
										/>
									) : (
										<div className="flex flex-col items-start gap-8 p-10">
											<div className="flex items-center gap-4">
												<strong>Format :</strong>
												<div className="flex items-center gap-1">
													<strong>PDF</strong>
													<input
														type="checkbox"
														defaultChecked
														onClick={(e: any) =>
															setSelectedExportValue(parseInt(e.target.value))
														}
														value={0}
													/>
												</div>
												<div className="flex items-center gap-1"></div>
											</div>
											<div className="flex items-center justify-center gap-4">
												<strong>Classement :</strong>
												<select
													className="h-10 rounded-md border border-gray-300 pl-2 focus:outline-none"
													value={selectedExportOption}
													onChange={(e) =>
														setSelectedExportOption(e.target.value)
													}
												>
													{rankingExportOptions.map((item: any) => {
														return (
															<option value={item.id} key={item.id}>
																{item.title}
															</option>
														);
													})}
												</select>
											</div>

											{selectedExportOption === "classementCategorie" ? (
												<div>
													<strong>Saut de page au changement de sexe : </strong>
													<input
														type="checkbox"
														checked={sexPageBreaker}
														onChange={() => {
															setSexPageBreaker(!sexPageBreaker);
														}}
													/>
												</div>
											) : (
												""
											)}

											<div>
												<strong>Afficher les non classés : </strong>
												<input
													type="checkbox"
													checked={adminOption}
													onChange={() => setAdminOption(!adminOption)}
												/>
											</div>

											<div className="flex max-w-[400px] flex-row flex-wrap gap-4">
												<div className="flex items-center gap-2">
													<input
														type="checkbox"
														checked={allChecked}
														onChange={handleAllCheckboxChange}
													/>
													<span>Toutes les courses</span>
												</div>
												{LiveConfig?.calendrier_child
													?.sort((a: any, b: any) => a.nom.localeCompare(b.nom))
													.map((item: any, index: any) => (
														<div
															key={item.id}
															className="flex items-center gap-2"
														>
															<input
																type="checkbox"
																value={item.id}
																checked={checkedState[index]}
																onChange={() => handleCheckboxChange(index)}
															/>
															<span>{item.nom}</span>
														</div>
													))}
											</div>
										</div>
									)
								}
							/>
						)}
					</div>
					<div className="flex flex-row items-center gap-2 text-sm">
						<select
							value={run}
							onChange={(e) => handleChangeRun(parseInt(e.target.value))}
							className="h-10 rounded-md border border-gray-300 pl-2 text-xs focus:outline-none"
						>
							<option className="text-sm" value={0}>
								{trad[lang].races}
							</option>
							{LiveConfig?.calendrier_child?.map((item: any) => (
								<option value={item.id} key={item.id}>
									{item.nom}
								</option>
							))}
						</select>

						{run > 0 && (
							<>
								<p>{trad[lang].between}</p>
								<select
									value={source[0]}
									onChange={(e) =>
										handleChangeSource(0, parseInt(e.target.value))
									}
									className="h-10 rounded-md border border-gray-300 pl-2 text-xs focus:outline-none"
								>
									<option value={0}>{trad[lang].none}</option>
									{Points.filter((item: any) => item.type != 3).map(
										(item: any) => (
											<option value={item.id} key={item.id}>
												{`${item.ligne} - ${item.name}`}
											</option>
										)
									)}
								</select>

								<p>{trad[lang].and}</p>

								<select
									value={source[1]}
									onChange={(e) =>
										handleChangeSource(1, parseInt(e.target.value))
									}
									className="h-10 rounded-md border border-gray-300 pl-2 text-xs focus:outline-none"
								>
									<option value={0}>{trad[lang].none}</option>
									{Points.filter((item: any) => item.type != 1).map(
										(item: any) => (
											<option value={item.id} key={item.id}>
												{`${item.ligne} - ${item.name}`}
											</option>
										)
									)}
								</select>
							</>
						)}
					</div>

					<input
						value={search}
						onChange={(e) => handleChangeSearch(e.target.value)}
						placeholder={trad[lang].search}
						className="h-10 rounded-md border border-gray-300 pl-2 text-xs focus:outline-none"
					/>
				</div>

				{newStatus > -1 && (
					<Modal
						style={{
							width: "50%",
							height: "30%"
						}}
						closeModal={() => setNewStatus(-1)}
						titleButton={trad[lang].confirm}
						cancelBtn
						deleteBtn
						onClickCancelButton={() => setNewStatus(-1)}
						titleCancelButton={trad[lang].cancel}
						onClickButton={handleUpdateStatus}
						children={
							<h2>{modalMessage[newStatus as keyof typeof modalMessage]}</h2>
						}
						rounded
					/>
				)}

				{openedSubscriptionWithoutId && (
					<CreateRunnerModal
						onClose={() => setOpenedSubscriptionWithoutId(false)}
					/>
				)}

				{openDelete && (
					<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,.5)]">
						<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white xl:w-3/5 2xl:w-2/5">
							<div className="flex items-start justify-between rounded-t border-b p-4">
								<h2 className="text-center text-2xl">
									Supprimer l'inscription
								</h2>
							</div>

							<div className="grid grid-cols-2 gap-5 p-5">
								<div className="col-span-2 flex flex-row gap-2 text-center">
									<p className="font-normal">
										Êtes vous sur de vouloir supprimer l'inscription de {}
									</p>
									<p className="font-semibold">{selectedRunner.d} ?</p>
								</div>
							</div>

							<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
								<button
									className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
									onClick={() => setOpenDetele(false)}
									disabled={loading}
								>
									{trad[lang].close}
								</button>
								<button
									className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
									onClick={() => deleteSubscription(selectedRunner.k)}
									disabled={loading}
								>
									{trad[lang].delete}
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default ManageRunnerMenu;
