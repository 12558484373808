import { Tooltip, Typography } from "@material-tailwind/react";
import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";
import {
	AiOutlineEdit,
	AiOutlineExclamationCircle,
	AiOutlineMail,
	AiOutlineMan,
	AiOutlineMore,
	AiOutlineWoman
} from "react-icons/ai";
import { BiHistory } from "react-icons/bi";
import { BsPeople } from "react-icons/bs";
import { CgArrowsExchange } from "react-icons/cg";
import { FaRegComment, FaRegTrashAlt, FaTransgender } from "react-icons/fa";
import { MdOutlineDangerous, MdOutlineMedicalServices } from "react-icons/md";
import { RiMoneyEuroBoxLine } from "react-icons/ri";
import { RxInput } from "react-icons/rx";
import { TbCertificate } from "react-icons/tb";
import { Link, useParams } from "react-router-dom";
import { updateSubscriptionStatus } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import { LiveContext } from "../../contexts/LiveContext";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import trad from "../../lang/traduction";
import { IDocumentKey } from "../../types/Lang";
import { REGEXP } from "../../utils/RegExp";
import { calculateStatus } from "../../utils/Subscription";
import Toast from "../../utils/Toasts";
import ChangeRaceModal from "../modals/ChangeRaceModal";
import SubscriptionToAnotherRace from "../modals/SubscriptionToAnotherRace";
import AccessGuard, { AccessKeys, AccessType } from "../navigation/AccessGuard";
import CommentsHistory from "./CommentsHistory";
import MessageHistory from "./MessageHistory";
import RegistrationsDetails from "./RegistrationsDetails";
import ValidationReason from "./ValidationReason";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";
import { deleteOneSub } from "../../api/admin";
import ConfirmationModal from "../modals/ConfirmationModal";

const DOCUMENT_TYPES: any = {
	licence: 2,
	certificat: 4,
	autorisationParentale: 5
};

const GENDERS: any = {
	1: <AiOutlineMan size={20} color="rgb(0, 150, 255)" />,
	2: <AiOutlineWoman size={20} color="rgb(222, 49, 99)" />,
	3: <FaTransgender size={20} color="rgb(255, 220, 49)" />
};

const RegistrationTableItem = ({
	nbMessageEvent,
	subscription,
	refetch,
	openDocument,
	setEditedSubscription,
	setReceiverMail,
	setOpenMail,
	setReceiverSubcriptionId,
	docsAsked,
	setSubscriptionsFilter
}: {
	nbMessageEvent: number;
	subscription: any;
	refetch: Function;
	openDocument: Dispatch<SetStateAction<any>>;
	setEditedSubscription: Function;
	setReceiverMail: Function;
	setOpenMail: Function;
	setReceiverSubcriptionId: Function;
	docsAsked: { licence: boolean; certificat: boolean; autorisation: boolean };
	setSubscriptionsFilter: Function;
}) => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const { isFFAEvent } = useContext(LiveContext);
	const { ref, isComponentVisible, setIsComponentVisible } =
		useOutsideClick(false);
	const [openedDetails, setOpenedDetails] = useState(false);
	const [openedValidation, setOpenedValidation] = useState(false);
	const [openedHistory, setOpenedHistory] = useState(false);
	const [openedComments, setOpenedComments] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [validationReason, setValidationReason] = useState(
		"Veuillez fournir votre certificat médical valide (1 an avant la date de la course) ou une licence."
	);
	const [openedChangeRace, setOpenedChangeRace] = useState(false);
	const [openedSubscriptionToAnotherRace, setOpenedSubscriptionToAnotherRace] =
		useState(false);
	const [loading, setLoading] = useState(false);
	const [havePPS, setHavePPS] = useState(false);
	const [haveNumLicence, setHaveNumLicence] = useState(false);

	const documents_icons = {
		licence: <TbCertificate size={18} />,
		certificat: <MdOutlineMedicalServices size={18} />,
		autorisation: <BsPeople size={18} />
	};

	const getComputedStyle = function (doc_key: string) {
		// ["licence", "certificat", "autorisation"]
		let obs: any = subscription?.observations
			? JSON.parse(subscription?.observations)
			: {};
		if (
			doc_key === "certificat" &&
			isDocumentValidatedByFederation(doc_key) === true
		)
			return "cursor-default border border-blue-300 bg-blue-100 text-blue-800 hover:bg-blue-300 hover:text-blue-900 hover:shadow-sm";
		else if (subscription[doc_key]?.id) {
			return status[subscription[doc_key].statut || 0].color;
		} else if (isDocumentValidatedByFederation(doc_key) === true) {
			return "cursor-default border border-blue-300 bg-blue-100 text-blue-800 hover:bg-blue-300 hover:text-blue-900 hover:shadow-sm";
		} else {
			return "border border-gray-300 bg-gray-100 text-gray-800 hover:bg-gray-300 hover:text-gray-900 hover:shadow-sm";
		}
	};

	useEffect(() => {
		let havePPS = false;
		let haveNumLicence = false;
		if (subscription) {
			try {
				let obs: any = subscription?.observations
					? JSON.parse(subscription?.observations)
					: {};
				havePPS = obs && obs.infoPersonne?.pps?.length > 0;
				haveNumLicence = obs && obs.infoPersonne?.numLicence?.length > 0;
			} catch (e: unknown) {
				console.warn("Error parsing observation", e);
			}
		}
		setHavePPS(havePPS);
		setHaveNumLicence(haveNumLicence);
	}, [subscription]);

	const isDocumentValidatedByFederation = function (doc_key: string) {
		let obs: any = subscription?.observations
			? JSON.parse(subscription?.observations)
			: {};
		if (
			doc_key === "licence" &&
			obs &&
			obs.infoPersonne?.federation?.length > 0 &&
			obs.infoPersonne?.numLicence?.length > 0
		) {
			return true;
		} else if (
			doc_key === "certificat" &&
			obs &&
			obs.infoPersonne?.certificateApprovedByFederation === true
		) {
			return true;
		}
		return false;
	};

	const getFederationName = function () {
		let obs: any = subscription?.observations
			? JSON.parse(subscription?.observations)
			: {};

		return obs && obs.infoPersonne?.federation?.length > 0
			? obs.infoPersonne?.federation
			: "";
	};

	const status = useMemo(
		() => [
			{
				valid: 0,
				status: 1,
				value: 0,
				live_status: 0,
				text: trad[lang].waiting,
				color:
					"border border-yellow-300 bg-yellow-100 text-yellow-800 hover:bg-yellow-300 hover:text-yellow-900 hover:shadow-sm",
				option: true
			},
			{
				valid: 1,
				status: 1,
				value: 1,
				live_status: 1,
				text: trad[lang].valid,
				color:
					"border border-green-300 bg-green-100 text-green-800 hover:bg-green-300 hover:text-green-900 hover:shadow-sm",
				option: true
			},
			{
				status: 0,
				valid: 2,
				value: 2,
				live_status: 0,
				text: trad[lang].invalid,
				color:
					"border border-red-300 bg-red-100 text-red-800 hover:bg-red-300 hover:text-red-900 hover:shadow-sm",
				option: true
			},
			{
				status: 0,
				valid: 0,
				value: 3,
				live_status: 0,
				text: trad[lang].reimbursed,
				color: "bg-gray-100 text-gray-800",
				option: false
			},
			{
				status: 0,
				valid: 0,
				value: -1,
				live_status: 0,
				text: "Erreur",
				color: "bg-gray-100 text-gray-800",
				option: false
			}
		],
		[lang, subscription]
	);

	const toggleOptions = (e: any) => {
		e.stopPropagation();
		setIsComponentVisible((old) => !old);
	};

	const openEdit = (e: any) => {
		e.stopPropagation();
		setEditedSubscription(subscription);
	};

	const openHistory = (e: any) => {
		e.stopPropagation();
		setOpenedHistory(true);
	};

	const openComments = (e: any) => {
		e.stopPropagation();
		setOpenedComments(true);
	};

	const openChangeRace = (e: any) => {
		e.stopPropagation();
		setOpenedChangeRace(true);
	};

	const sendSubscriptionToAnotherRace = (e: any) => {
		e.stopPropagation();
		setOpenedSubscriptionToAnotherRace(true);
	};

	const openSpecificDocument = (e: any, doc: any, key: string) => {
		e.stopPropagation();

		// Document is already approved by Federation
		// No document to open
		if (isDocumentValidatedByFederation(key) === true) return;

		openDocument({
			...doc,
			idInscription: subscription.id,
			id: doc?.id || "new",
			key,
			doc_key: key
		});
	};

	const deleteSubscription = async (inscriptionId: any) => {
		try {
			await deleteOneSub(inscriptionId);
			await refetch();
			Toast.success(trad[lang].deletionSuccess);
		} catch {
			Toast.error(trad[lang].deletionError);
		}
	};

	const handleStatusChange = async (value: string) => {
		try {
			setLoading(true);
			setIsComponentVisible((old) => !old);
			if (value == "2" && !validationReason) {
				Toast.error(trad[lang].sub_validation_message);
				return;
			}

			const cur_status = status.filter(
				(item) => item.value == parseInt(value)
			)[0];

			await updateSubscriptionStatus(subscription.id, {
				status: cur_status.status,
				valid: cur_status.valid,
				live_status: cur_status.live_status,
				message: value == "2" ? validationReason : null
			});

			setOpenedValidation(false);
			refetch();
			Toast.success(trad[lang].updateSuccess);
		} catch (error) {
			Toast.error(trad[lang].updateError);
			throw error;
		} finally {
			setLoading(false);
		}
	};

	const openDetails = () => setOpenedDetails((old) => true);
	const closeDetails = () => setOpenedDetails((old) => false);

	const changeStatus = async (value: string) => {
		if (value == "0" || value == "1") {
			await handleStatusChange(value);
			return;
		}

		setOpenedValidation(true);
	};

	const sendSingleMail = async (e: any) => {
		setReceiverMail(subscription.person.email);
		setReceiverSubcriptionId(subscription.id);
		setOpenMail(true);
		e.stopPropagation();
	};

	const handleTeam = (e: any, team: string) => {
		e.stopPropagation();

		if (team) {
			setSubscriptionsFilter((old: any) => ({
				...old,
				name: subscription.equipe?.nom
			}));
		}
	};

	const obs = subscription?.observations
		? JSON.parse(subscription?.observations)
		: {};

	const messages = (obs?.message?.length || 0) + nbMessageEvent;
	const comments = obs?.commentaire?.length || 0;

	const receiver =
		subscription.person.firstname + " " + subscription.person.lastname;

	const askedDocs = ["licence", "certificat", "autorisation"].filter(
		(item) => (docsAsked as any)[item]
	);
	return (
		<>
			<tr
				className={`cursor-pointer transition-colors hover:bg-gray-50 ${
					openedDetails ? "bg-gray-50" : ""
				} ${
					subscription?.run_status === 0
						? ""
						: subscription?.run_status === 1
						? "bg-gray-300 text-black" // Non Partant
						: subscription?.run_status === 2
						? "bg-yellow-300 text-black" // Abandon
						: subscription?.run_status === 3
						? "bg-red-300 text-black line-through" // Disqualifié
						: ""
				}`}
				onClick={openedDetails ? closeDetails : openDetails}
				aria-disabled={subscription?.run_status < 0}
			>
				<td className="hidden w-[150px] whitespace-nowrap px-1.5 py-1.5 text-sm font-medium text-gray-700 md:table-cell">
					<div className="flex items-center gap-x-2">
						<h2 className="font-medium text-gray-800">
							{subscription.created_at
								? new Date(subscription.created_at).toLocaleDateString()
								: ""}
						</h2>
					</div>
				</td>

				<td className="hidden w-[150px] whitespace-nowrap px-1.5 py-1.5 text-sm font-medium text-gray-700 md:table-cell">
					<div className="flex items-center gap-x-2">
						<h2 className="font-medium text-gray-800">{subscription.bib}</h2>
					</div>
				</td>

				<td className="whitespace-nowrap px-1.5 py-1.5 text-sm font-medium text-gray-700">
					<div className="flex items-center gap-x-2">
						<Tooltip
							className="text-md z-50 text-center"
							content={<Typography>{subscription.person.name}</Typography>}
							hidden={subscription.person.name.length < 12}
						>
							<h2 className="flex flex-col font-medium text-gray-800">
								{subscription.equipe?.nom && (
									<p
										className="mb-1 w-fit rounded-md bg-gray-200 px-2 transition-all hover:bg-gray-400"
										onClick={(e) => handleTeam(e, subscription.equipe.nom)}
									>
										{subscription.equipe.nom}{" "}
									</p>
								)}
								<p className="mr-2 flex flex-row gap-2">
									{subscription.person.name}
									{subscription.person.sex > 0 &&
										GENDERS[subscription.person.sex]}
									{subscription?.run_status === 0 ? (
										""
									) : subscription?.run_status === 1 ? (
										<>
											|{" "}
											<span className="font-bold">
												{trad[lang].non_starters}
											</span>
										</>
									) : subscription?.run_status === 2 ? (
										<div className="flex items-center justify-center gap-2">
											| <AiOutlineExclamationCircle color="red" />
											<span className="font-bold">{trad[lang].surrended}</span>
										</div>
									) : subscription?.run_status === 3 ? (
										<div className="flex items-center justify-center gap-2">
											| <MdOutlineDangerous color="red" />
											<span className="font-bold">
												{trad[lang].disqualified}
											</span>
										</div>
									) : (
										""
									)}
								</p>
							</h2>
						</Tooltip>
					</div>
				</td>

				<td className="hidden whitespace-nowrap px-1.5 py-1.5 text-sm font-medium text-gray-700 md:table-cell">
					<div className="flex items-center gap-x-2">
						<h2 className="max-w-[200px] truncate font-medium text-gray-800 xl:max-w-xs">
							{subscription.calendrier.nom}
						</h2>
					</div>
				</td>

				<td className="whitespace-nowrap px-1.5 py-1.5 text-sm font-medium text-gray-700">
					<div
						className={`inline-flex items-center gap-x-2 rounded-full ${
							status[calculateStatus(subscription)]?.color
						} text-sm transition-colors`}
					>
						{status[calculateStatus(subscription)]?.value > 2 ? (
							<h2 className="w-full min-w-[135px] py-1 text-center text-sm">
								{status[calculateStatus(subscription)].text}
							</h2>
						) : (
							<select
								className="w-fit min-w-[135px] appearance-none border-none border-transparent bg-transparent py-1 px-0 text-center text-sm focus:border-transparent focus:shadow-none focus:ring-0"
								value={status[calculateStatus(subscription)]?.value}
								onClick={(e) => e.stopPropagation()}
								onChange={(e) => changeStatus(e.target.value)}
							>
								{status
									.filter((item) => item.option)
									.map((item) => (
										<option
											className="p-0 text-black"
											value={item.value}
											key={item.value}
										>
											{item.text}
										</option>
									))}
							</select>
						)}
					</div>
				</td>

				<td className="whitespace-nowrap px-1.5 py-1.5 text-sm font-medium text-gray-700">
					<div className="flex items-center justify-between gap-x-2 px-4">
						{askedDocs.map((doc_key, index) => (
							<Tooltip
								className="text-md z-50 text-center"
								key={index}
								content={
									<>
										<Typography>
											{doc_key === "certificat" && isFFAEvent === true
												? (havePPS && haveNumLicence) ||
												  (!havePPS && !haveNumLicence)
													? "PPS ou Certificat médical (mineurs)"
													: !havePPS
													? "Numéro de licence"
													: "PPS"
												: trad[lang][doc_key as IDocumentKey]}
										</Typography>
										{isDocumentValidatedByFederation(doc_key) === true ? (
											<Typography>
												{trad[lang].validated}
												{havePPS && haveNumLicence && lang === "fr"
													? "s"
													: ""}{" "}
												{`${trad[lang].by} ${trad[lang].la} ${
													getFederationName() || "FFA"
												}`}
											</Typography>
										) : (
											<Typography>
												{doc_key === "certificat" && isFFAEvent === true
													? trad[lang].missing
													: ""}
											</Typography>
										)}
									</>
								}
							>
								<button
									key={doc_key}
									onClick={(e) =>
										openSpecificDocument(e, subscription[doc_key], doc_key)
									}
									className={`grid h-7 w-7 place-items-center rounded-full transition-colors ${getComputedStyle(
										doc_key
									)}`}
								>
									{documents_icons[doc_key as keyof typeof documents_icons]}
								</button>
							</Tooltip>
						))}
					</div>
				</td>

				<td className="whitespace-nowrap px-1.5 py-1.5 text-sm font-medium text-gray-700">
					<div className="flex items-center justify-end gap-x-2">
						<button onClick={(e) => openEdit(e)}>
							<AiOutlineEdit size={25} />
						</button>
					</div>
				</td>

				<td
					className="relative whitespace-nowrap px-1.5 py-1.5 text-sm font-medium text-gray-700"
					ref={ref}
				>
					<div className="flex items-center justify-center gap-x-2">
						<button onClick={(e) => toggleOptions(e)}>
							<AiOutlineMore size={25} />
						</button>
					</div>

					{/* Options modal handling */}
					{isComponentVisible && (
						<div className="absolute right-0 z-20 mt-2 origin-top-right rounded-md border bg-white py-2 shadow-xl">
							{subscription.ide_type_inscription == 1 && (
								<Link
									className="flex w-full flex-row items-center justify-end py-3 px-3 text-left text-sm text-gray-600 duration-300 hover:bg-gray-100"
									to={`/${slug}/registration-refund/${subscription.id}`}
								>
									{trad[lang].reimburse}
									<RiMoneyEuroBoxLine className="ml-2 h-5 w-5" />
								</Link>
							)}

							{subscription.person.email &&
								REGEXP.mail.test(subscription.person.email) && (
									<button
										className="flex w-full flex-row items-center justify-end py-3 px-3 text-left text-sm text-gray-600 duration-300 hover:bg-gray-100"
										onClick={sendSingleMail}
									>
										{trad[lang].sendMail}
										<AiOutlineMail className="ml-2 h-5 w-5" />
									</button>
								)}

							<AccessGuard
								permissionKey={AccessKeys.SUBSCRIPTIONS_MSG_HISTORY}
								type={AccessType.BUTTON}
							>
								<button
									className="flex w-full flex-row items-center justify-end py-3 px-3 text-left text-sm text-gray-600 duration-300 hover:bg-gray-100"
									onClick={openHistory}
								>
									<div className=" mr-[8px] flex h-4 min-w-[1rem] items-center justify-center rounded-full bg-red-600 text-[10px] text-white transition-colors">
										{messages}
									</div>
									{trad[lang].see_message_history}
									<BiHistory className="ml-2 h-5 w-5" />
								</button>
							</AccessGuard>
							<button
								className="flex w-full flex-row items-center justify-end py-3 px-3 text-left text-sm text-gray-600 duration-300 hover:bg-gray-100"
								onClick={openComments}
							>
								<div className=" mr-[8px] flex h-4 min-w-[1rem] items-center justify-center rounded-full bg-red-600 text-[10px] text-white transition-colors">
									{comments}
								</div>
								{trad[lang].seeComments}
								<FaRegComment className="ml-2 h-5 w-5" />
							</button>
							{subscription.status == 1 && (
								<button
									className="flex w-full flex-row items-center justify-end py-3 px-3 text-sm text-gray-600 duration-300 hover:bg-gray-100"
									onClick={openChangeRace}
								>
									{trad[lang].changeRace}
									<CgArrowsExchange className="ml-2 h-6 w-6" />
								</button>
							)}

							<button
								className="flex w-full flex-row items-center justify-end py-3 px-3 text-sm text-gray-600 duration-300 hover:bg-gray-100"
								onClick={sendSubscriptionToAnotherRace}
							>
								<b className="mr-2">[File d'attente]</b>
								Inscription à une autre course
								<RxInput className="ml-2 h-6 w-6" />
							</button>

							{subscription.ide_type_inscription == 1 && (
								<Link
									className="flex w-full justify-end py-3 px-3 text-right text-sm text-gray-600 duration-300 hover:bg-gray-100"
									to={`/${slug}/registration-order/${subscription.id}`}
								>
									{trad[lang].see_order}
								</Link>
							)}
							{subscription.ide_type_inscription !== 1 && (
								<button
									className="flex w-full flex-row items-center justify-end py-3 px-3 text-left text-sm text-gray-600 duration-300 hover:bg-gray-100"
									onClick={() => setOpenDelete(true)}
								>
									{trad[lang].deleteSub}
									<FaRegTrashAlt className="ml-2 h-6 w-6" />
								</button>
							)}
						</div>
					)}
				</td>
			</tr>

			{openDelete && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,.5)]">
					<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white xl:w-3/5 2xl:w-2/5">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h2 className="text-center text-2xl">{trad[lang].deleteSub}</h2>
						</div>

						<div className="grid grid-cols-2 gap-5 p-5">
							<div className="col-span-2 flex flex-row gap-2 text-center">
								<p className="font-normal">
									{trad[lang].confirmDeleteSub}
									{}
								</p>
								<p className="font-semibold">
									{" "}
									{subscription.person.firstname}
								</p>
								<p className="font-semibold">
									{subscription.person.lastname} ?
								</p>
							</div>
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
								onClick={() => setOpenDelete(false)}
								disabled={loading}
							>
								{trad[lang].close}
							</button>
							<button
								className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
								onClick={() => deleteSubscription(subscription.id)}
								disabled={loading}
							>
								{trad[lang].delete}
							</button>
						</div>
					</div>
				</div>
			)}

			{openedDetails && (
				<RegistrationsDetails
					subscription={subscription}
					openDocument={openSpecificDocument}
					status={status}
					docsAsked={docsAsked}
				/>
			)}

			{openedValidation && (
				<ValidationReason
					loading={loading}
					setOpenedValidation={setOpenedValidation}
					setValidationReason={setValidationReason}
					validationReason={validationReason}
					handleStatusChange={handleStatusChange}
				/>
			)}

			{openedHistory && (
				<MessageHistory
					setOpenedHistory={setOpenedHistory}
					idInscription={subscription.id}
					receiver={receiver}
					bib={subscription?.bib?.toString()}
				/>
			)}

			{openedComments && (
				<CommentsHistory
					setOpenedHistory={setOpenedComments}
					idInscription={subscription.id}
					subscriptionRefetch={refetch}
					receiver={receiver}
					bib={subscription?.bib?.toString()}
				/>
			)}

			{openedChangeRace && subscription.status == 1 && (
				<ChangeRaceModal
					setOpenedChangeRace={setOpenedChangeRace}
					subscription={subscription}
					refetch={refetch}
				/>
			)}

			{openedSubscriptionToAnotherRace && (
				<SubscriptionToAnotherRace
					setOpenedSubscriptionToAnotherRace={
						setOpenedSubscriptionToAnotherRace
					}
					subscription={subscription}
					refetch={refetch}
				/>
			)}
		</>
	);
};

export default RegistrationTableItem;
