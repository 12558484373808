import { Tooltip, Typography } from "@material-tailwind/react";
import { useContext, useState } from "react";
import {
	AiOutlineCheck,
	AiOutlineClose,
	AiOutlineInfoCircle,
	AiOutlineLoading3Quarters
} from "react-icons/ai";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
	createTeam,
	getEventRunsConfig,
	getTeamsAndClubs
} from "../../api/event";
import { getLiveConfig, getRunner, updateRunnerInfos } from "../../api/live";
import { getCategories } from "../../api/subscription";
import { AppContext } from "../../contexts/AppContext";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";
import trad from "../../lang/traduction";
import Toast from "../../utils/Toasts";
import { calculateAge } from "../../utils/DateFormater";

const EditRunnerModal = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const {
		openedSubscription,
		setOpenedSubscription,
		LiveDataRefetch,
		LiveData
	} = useContext(ManageRunnerContext);
	const [loading, setLoading] = useState(false);
	const [editForm, setEditForm] = useState({
		dossard: 0,
		nom: "",
		prenom: "",
		sexe: 0,
		email: "",
		dateNaissance: "",
		idCalendrier: 0,
		idEquipe: 0,
		handicap: "00:00:00",
		idCategorie: 0
	});
	const [teamName, setTeamName] = useState("");

	const { data: RunsInfos = [] } = useQuery({
		queryKey: ["runs_info", slug],
		queryFn: () => getEventRunsConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});
	const run = RunsInfos.find((item: any) => item.id === editForm?.idCalendrier);
	const runnerAge = calculateAge(editForm?.dateNaissance);
	const minAge = run?.prices[0]?.minAge;
	const maxAge = run?.prices[0]?.maxAge;
	const isMinAgeAvailable = runnerAge < minAge && minAge > 0;
	const isMaxAgeAvailable = runnerAge > maxAge && maxAge > 0;
	const isAgeUnavailable = isMinAgeAvailable || isMaxAgeAvailable;

	const { data: Categories, isLoading: CategoriesLoading } = useQuery({
		queryKey: ["categories", slug],
		queryFn: () => getCategories(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const handleForm = (key: string, value: any) =>
		setEditForm((old) => ({ ...old, [key]: value }));

	const { data: LiveConfig, isLoading: LiveConfigLoading } = useQuery({
		queryKey: ["live_config", slug],
		queryFn: () => getLiveConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const {
		data,
		isLoading,
		refetch: refetchRunner
	} = useQuery({
		queryKey: ["subscription", slug, openedSubscription],
		queryFn: () => getRunner(slug as string, openedSubscription),
		enabled: !!openedSubscription,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			const runnerLiveData = LiveData?.result?.find(
				(item) => item.k === openedSubscription
			);
			setEditForm({
				dossard: data.dossard,
				nom: data.nom,
				prenom: data.prenom,
				sexe: data.sexe,
				email: data.email,
				dateNaissance: data.dateNaissance
					? data.dateNaissance.split("T")[0]
					: "",
				idCalendrier: data.idCalendrier,
				idEquipe: data.idEquipe,
				handicap: data.handicap || "00:00:00",
				idCategorie: runnerLiveData?.i || 0
			});
		}
	});

	const {
		data: Teams = { teams: [] },
		isLoading: isLoadingTeams,
		refetch: refetchTeams
	} = useQuery({
		queryKey: ["teamClub", slug],
		queryFn: () => getTeamsAndClubs(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const close = () => setOpenedSubscription(0);

	const save = async () => {
		try {
			setLoading(true);

			await updateRunnerInfos(slug as string, openedSubscription, editForm);

			await LiveDataRefetch();
			setOpenedSubscription(0);
			Toast.success("Modification effectuée avec succès");
		} catch (error) {
			Toast.error(trad[lang].errorVerifyBibAlreadyUse);
			throw error;
		} finally {
			setLoading(false);
		}
	};

	const handleCreateTeam = async () => {
		try {
			await createTeam(slug as string, {
				idCalendrier: data.idCalendrier,
				idInscription: data.idInscription,
				team: teamName
			});

			await refetchTeams();
			await refetchRunner();
		} catch (error) {
			throw error;
		}
	};

	if (!openedSubscription) {
		return <></>;
	}

	if (isLoading || LiveConfigLoading) {
		return (
			<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
				<div className="min-w-[300px] max-w-screen-lg rounded-md border bg-white sm:w-4/5 md:w-2/5 ">
					<AiOutlineLoading3Quarters size={45} className="ml-2 animate-spin" />
				</div>
			</div>
		);
	}

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="w-4/5 min-w-[300px] max-w-screen-lg rounded-md border bg-white md:w-3/5 lg:w-2/5 ">
				{/* Modal Header */}
				<div className="flex items-start justify-between rounded-t border-b p-4">
					<h3 className="text-xl font-semibold">{trad[lang].editRunner}</h3>
					<button
						type="button"
						className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={close}
					>
						<AiOutlineClose size={16} />
						<span className="sr-only">Close modal</span>
					</button>
				</div>
				<div className="w-full text-center">
					{isMinAgeAvailable ? (
						<p className="italic text-red-500">
							*L'âge minimum requis est de {minAge} ans*
						</p>
					) : null}
					{isMaxAgeAvailable ? (
						<p className="italic text-red-500">
							*L'âge maximum requis est de {maxAge} ans*
						</p>
					) : null}
				</div>
				<div className="max-h-[70vh] overflow-y-auto px-4 pt-3 pb-3 md:flex md:flex-row">
					<div className="w-full pb-4 md:w-1/2 md:pr-4 md:pb-0">
						<h2 className="mb-4 flex items-center gap-2 font-bold">
							Identité
							<Tooltip
								className="text-md z-50 text-center"
								content={
									<Typography>{trad[lang].editRunnerTooltip}</Typography>
								}
							>
								<p>
									<AiOutlineInfoCircle />
								</p>
							</Tooltip>
						</h2>
						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].name}</p>

							<input
								type="text"
								value={editForm.nom}
								onChange={(e) => handleForm("nom", e.target.value)}
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
							/>
						</div>

						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].firstname}</p>

							<input
								type="text"
								value={editForm.prenom}
								onChange={(e) => handleForm("prenom", e.target.value)}
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
							/>
						</div>

						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].sexeChoice}</p>

							<select
								value={editForm.sexe}
								onChange={(e) => handleForm("sexe", parseInt(e.target.value))}
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
							>
								<option value={0}>
									{trad[lang].placeholder_select_gender}
								</option>
								<option value={1}>{trad[lang].man}</option>
								<option value={2}>{trad[lang].woman}</option>
								<option value={3}>{trad[lang].other_gender}</option>
							</select>
						</div>

						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].email}</p>

							<input
								type="text"
								value={editForm.email}
								onChange={(e) => handleForm("email", e.target.value)}
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
							/>
						</div>

						<div className="mb-2 flex flex-col gap-1">
							<p
								className={`${
									isAgeUnavailable ? "text-red-500" : "text-black"
								}`}
							>
								{trad[lang].birthDate}
							</p>

							<input
								type="date"
								value={editForm.dateNaissance}
								onChange={(e) => handleForm("dateNaissance", e.target.value)}
								className={`h-12 rounded-md border ${
									isAgeUnavailable
										? "border-red-500 text-red-500"
										: "border-gray-300"
								} pl-2 focus:outline-none`}
							/>
						</div>
					</div>

					<div className="w-full md:w-1/2">
						<h2 className="mb-4 flex items-center gap-2 font-bold">
							Course
							<Tooltip
								className="text-md z-50 text-center"
								content={
									<Typography>{trad[lang].editRunnerRunTooltip}</Typography>
								}
							>
								<p>
									<AiOutlineInfoCircle />
								</p>
							</Tooltip>
						</h2>

						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].run}</p>

							<select
								value={editForm.idCalendrier}
								onChange={(e) =>
									handleForm("idCalendrier", parseInt(e.target.value))
								}
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
							>
								<option value={0}>{trad[lang].selectRunPlaceholder}</option>
								{LiveConfig?.calendrier_child?.map((item: any) => (
									<option value={item.id} key={item.id}>
										{item.nom}
									</option>
								))}
							</select>
						</div>

						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].bib}</p>

							<input
								type="text"
								value={editForm.dossard}
								onChange={(e) =>
									handleForm("dossard", parseInt(e.target.value))
								}
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
							/>
						</div>

						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].team}</p>

							{editForm.idEquipe === -1 ? (
								<div className="flex flex-row gap-2">
									<input
										type="text"
										value={teamName}
										onChange={(e) => setTeamName(e.target.value)}
										className="h-12 flex-grow rounded-md border border-gray-300 pl-2 focus:outline-none"
									/>

									<button
										className="flex h-12 w-12 items-center justify-center rounded-md bg-red-600 transition-colors hover:bg-red-700"
										onClick={() => handleForm("team", 0)}
									>
										<AiOutlineClose size={25} color="#fff" />
									</button>
									<button
										className="flex h-12 w-12 items-center justify-center rounded-md bg-green-600 transition-colors hover:bg-green-700"
										onClick={handleCreateTeam}
									>
										<AiOutlineCheck size={25} color="#fff" />
									</button>
								</div>
							) : (
								<select
									value={editForm.idEquipe}
									onChange={(e) =>
										handleForm("idEquipe", parseInt(e.target.value))
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								>
									<option value={0}>{trad[lang].chooseTeam}</option>
									<option value={-1}>{trad[lang].createTeam}</option>
									{Teams.teams?.map((item: any) => (
										<option value={item.id} key={item.id}>
											{item.nom}
										</option>
									))}
								</select>
							)}
						</div>

						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].handicap}</p>

							<input
								type="time"
								value={editForm.handicap}
								step={1}
								onChange={(e) => handleForm("handicap", e.target.value)}
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
							/>
						</div>

						<div className="mb-2 flex flex-col gap-1">
							<p>{trad[lang].category}</p>
							<select
								className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								onChange={(e) => handleForm("idCategorie", e.target.value)}
								value={editForm.idCategorie}
							>
								{Categories &&
									Categories.filter((i: any) => i.sex === editForm.sexe).map(
										(item: any) => {
											return (
												<option key={item.id} value={item.id}>
													{item.name}
												</option>
											);
										}
									)}
							</select>
						</div>
					</div>
				</div>

				<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
					<button
						type="button"
						className={`rounded-lg border border-primary bg-white px-5 py-2.5 text-center text-sm font-medium text-primary focus:outline-none ${
							loading ? "opacity-50" : ""
						}`}
						disabled={loading}
						onClick={close}
					>
						{trad[lang].close}
					</button>
					<button
						type="button"
						className={`rounded-lg border bg-primary px-5 py-2.5 text-center text-sm font-medium text-white transition-all hover:bg-primarymedium focus:outline-none ${
							loading ? "opacity-50" : ""
						}`}
						disabled={loading || isAgeUnavailable}
						onClick={save}
					>
						{trad[lang].save}
					</button>
				</div>
			</div>
		</div>
	);
};

export default EditRunnerModal;
