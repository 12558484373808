import { useContext } from "react";
import Layout from "../../components/navigation/Layout";
import { AppContext } from "../../contexts/AppContext";
import useScript from "../../hooks/useScript";

const Shop = () => {
	const { lang } = useContext(AppContext);
	const processEnv = process.env;

	useScript(
		(processEnv &&
			processEnv?.REACT_APP_JETCODES_URL?.replace("${lang}", lang)) ||
			"",
		[lang]
	);

	return (
		<Layout active_key="shop">
			<div className="flex-col items-center overflow-y-auto p-5">
				{/* TITLE */}
				<h1 className="mb-6 mt-6 text-center text-2xl font-bold text-gloom md:text-3xl">
					Boutique
				</h1>
				{/* BASKET */}
				<div className="basket-jetcode" data-basket-id="0"></div>
				{/* CONTENT */}
				<div className="w h-screen">
					{/* PRODUCTS */}
					<div
						className="container-jetcode"
						data-container-id={processEnv.REACT_APP_SHOP_IDPRODUIT}
						css-jetcode-href="https://content.dag-system.com/chronometragecom/product.css"
						jetcode-options="displayProductImage"
					></div>
				</div>
			</div>
		</Layout>
	);
};

export default Shop;
