import { Tooltip, Typography } from "@material-tailwind/react";
import { useContext, useEffect, useState } from "react";
import {
	AiOutlineCheck,
	AiOutlineClose,
	AiOutlineDelete,
	AiOutlineEdit,
	AiOutlineLoading3Quarters,
	AiOutlineReload
} from "react-icons/ai";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { insertDetection } from "../../api/detection";
import { postMessage } from "../../api/hook";
import { getDetectionsForBib, getEventLines } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import { ManageRunnerContext } from "../../contexts/ManageRunnerContext";
import trad from "../../lang/traduction";
import { formatLikeISO } from "../../utils/DateFormater";
import { padNumber } from "../../utils/Pad";
import Toast from "../../utils/Toasts";

const HandleDetectionModal = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const { openedDetections, setOpenedDetections, LiveDataRefetch, LiveConfig } =
		useContext(ManageRunnerContext);
	const [fullTime, setFullTime] = useState(false);
	const [inputDate, setInputDate] = useState({
		id: 0,
		date: formatLikeISO(new Date())
	});
	const [addDetection, setAddDetection] = useState<{
		ligne: number;
		date: string;
	}>();

	const close = () => setOpenedDetections(0);

	const { data: EventLines = [], isLoading: EventLinesLoading } = useQuery({
		queryKey: ["event_lines", slug],
		queryFn: () => getEventLines(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const {
		data = [],
		isLoading,
		refetch: refetchDetections
	} = useQuery({
		queryKey: ["detections", openedDetections],
		queryFn: () =>
			getDetectionsForBib(slug as string, openedDetections, LiveConfig.id),
		refetchOnWindowFocus: false,
		enabled: !!slug && !!LiveConfig?.id
	});

	const handleUpdateDetection = async () => {
		try {
			if (!LiveConfig.id) {
				Toast.error(trad[lang].deletionError);
				return;
			}

			const detection = data.find(
				(item: any) => item.IDDetection == inputDate.id
			);

			if (!detection) return;

			await postMessage(LiveConfig.id, {
				action: "modifyDetection",
				detection: {
					IDDetection: detection.IDDetection,
					LineNumber: detection.ligne,
					Information: detection.dossard,
					typeDetection: detection.type,
					DateDetection: new Date(inputDate.date).toISOString(),
					Supprimee: 0,
					Modifie: 1
				}
			});

			setInputDate({ id: 0, date: formatLikeISO(new Date()) });

			await refetchDetections();
			await LiveDataRefetch();

			Toast.success(trad[lang].updateSuccess);
		} catch (error) {
			Toast.error(trad[lang].verifyPrestation);
			throw error;
		}
	};

	const handleDeleteDetection = async (IDDetection: number) => {
		try {
			if (!LiveConfig.id) {
				Toast.error(trad[lang].deletionError);
				return;
			}

			const detection = data.find(
				(item: any) => item.IDDetection == IDDetection
			);

			if (!detection) return;

			await postMessage(LiveConfig.id, {
				action: "modifyDetection",
				detection: {
					IDDetection: detection.IDDetection,
					LineNumber: detection.ligne,
					Information: detection.dossard,
					DateDetection: detection.date,
					typeDetection: detection.type,
					Supprimee: 1,
					Modifie: 0
				}
			});

			setInputDate({ id: 0, date: formatLikeISO(new Date()) });
			await refetchDetections();
			await LiveDataRefetch();

			Toast.success(trad[lang].updateSuccess);
		} catch (error) {
			Toast.error(trad[lang].verifyPrestation);
			throw error;
		}
	};

	const handleRestoreDetection = async (IDDetection: number) => {
		try {
			if (!LiveConfig.id) {
				Toast.error(trad[lang].deletionError);
				return;
			}

			const detection = data.find(
				(item: any) => item.IDDetection == IDDetection
			);

			if (!detection) return;

			await postMessage(LiveConfig.id, {
				action: "modifyDetection",
				detection: {
					IDDetection: detection.IDDetection,
					LineNumber: detection.ligne,
					Information: detection.dossard,
					DateDetection: detection.date,
					typeDetection: detection.type,
					Supprimee: 0,
					Modifie: 0
				}
			});

			setInputDate({ id: 0, date: formatLikeISO(new Date()) });
			await refetchDetections();

			Toast.success(trad[lang].updateSuccess);
		} catch (error) {
			Toast.error(trad[lang].verifyPrestation);
			throw error;
		}
	};

	const statutColors = [
		"bg-red-100",
		"bg-white",
		"bg-yellow-100",
		"bg-green-100"
	];

	const addRowDetection = () =>
		setAddDetection({ ligne: 0, date: formatLikeISO(new Date()) });

	const handleChangeAddDetection = (key: string, value: any) => {
		setAddDetection((old: any) => ({ ...old, [key]: value }));
	};

	const handleAddDetection = async () => {
		try {
			if (!LiveConfig.id || !addDetection?.ligne || !addDetection.date) {
				Toast.error(trad[lang].insertError);
				return;
			}

			const date = new Date(addDetection.date);

			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			const time = `${padNumber(date.getHours(), 2)}:${padNumber(
				date.getMinutes(),
				2
			)}:${padNumber(date.getSeconds(), 2)}.${padNumber(
				date.getMilliseconds(),
				2
			)}`;

			const trame = `000001;99;${openedDetections};${time};${day}-${month}-${year};Insert Chronometrage`;

			await insertDetection(
				addDetection.ligne.toString(),
				trame,
				LiveConfig.credential
			);

			setAddDetection(undefined);

			setTimeout(async () => {
				await refetchDetections();
			}, 500);

			Toast.success(trad[lang].updateSuccess);
		} catch (error) {
			Toast.error(trad[lang].verifyPrestation);
			throw error;
		}
	};

	const noLive = !LiveConfig?.prestation || LiveConfig?.date_fin_prestation;

	useEffect(() => {
		setInputDate({ id: 0, date: formatLikeISO(new Date()) });
		setAddDetection(undefined);
	}, [openedDetections]);

	if (!openedDetections) {
		return <></>;
	}
	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="w-11/12 min-w-[300px] max-w-screen-lg rounded-md border bg-white md:w-4/5 lg:w-3/5 ">
				{/* Modal Header */}
				<div className="flex items-start justify-between rounded-t border-b p-4">
					<h3 className="text-xl font-semibold">
						{`${trad[lang].handleDetection} ${
							data.length > 0 ? `- ${data[0].nom} ${data[0].prenom}` : ""
						} n°${openedDetections}`}
					</h3>
					<button
						type="button"
						className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={close}
					>
						<AiOutlineClose size={16} />
						<span className="sr-only">Close modal</span>
					</button>
				</div>

				<div className="max-h-[70vh] overflow-y-auto px-4 pt-3 pb-3">
					<div className="mb-1 flex w-full flex-row border-b border-gray-300">
						<p className="w-2/12 px-1 font-mono">{trad[lang].detectionLigne}</p>
						<p className="w-4/12 px-1 font-mono">
							{trad[lang].detectionPointName}
						</p>
						<p className="w-4/12 px-1 font-mono">{trad[lang].date}</p>
						<p className="w-2/12 px-1 text-right font-mono">
							{trad[lang].actions}
						</p>
					</div>

					{isLoading && (
						<AiOutlineLoading3Quarters
							size={45}
							className="ml-2 animate-spin"
						/>
					)}

					{data.map((item: any) => (
						<div
							className={`mb-1 flex h-12 w-full flex-row items-center rounded-md border-b border-gray-100 py-1 ${
								statutColors[item.statut]
							}`}
							key={item.IDDetection}
						>
							<p className="w-2/12 px-1 font-mono">{item.ligne}</p>
							<p className="w-4/12 px-1 font-mono">{item.nomPointage}</p>
							<p className="w-4/12 px-1 font-mono">
								{inputDate.id == item.IDDetection ? (
									<input
										value={inputDate.date}
										className="h-10 rounded-md border border-gray-300 focus:outline-none"
										step={0.01}
										onChange={(e) =>
											setInputDate({
												id: item.IDDetection,
												date: e.target.value
											})
										}
										type="datetime-local"
									/>
								) : (
									new Date(item.date)
										.toLocaleString(
											undefined,
											fullTime
												? {
														day: "2-digit",
														month: "2-digit",
														year: "numeric",
														hour: "2-digit",
														minute: "2-digit",
														second: "2-digit",
														fractionalSecondDigits: 2,
														timeZone: LiveConfig.timezone
												  }
												: {
														hour: "2-digit",
														minute: "2-digit",
														second: "2-digit",
														fractionalSecondDigits: 2,
														timeZone: LiveConfig.timezone
												  }
										)
										.split(",")
										.join(".")
								)}
							</p>
							<p className="flex w-2/12 flex-row justify-end gap-2 px-1 text-center font-mono">
								{inputDate.id == item.IDDetection ? (
									<>
										<AiOutlineCheck
											size={25}
											color="#fff"
											className="cursor-pointer rounded bg-green-600 p-1"
											onClick={handleUpdateDetection}
										/>
										<AiOutlineClose
											size={25}
											color="#fff"
											className="cursor-pointer rounded bg-red-600 p-1"
											onClick={() =>
												setInputDate({ id: 0, date: formatLikeISO(new Date()) })
											}
										/>
									</>
								) : (
									<>
										<AiOutlineEdit
											size={25}
											color="#fff"
											className="cursor-pointer rounded bg-blue-600 p-1"
											onClick={() =>
												setInputDate({
													id: item.IDDetection,
													date: formatLikeISO(new Date(item.date))
												})
											}
										/>
										{item.statut == 0 ? (
											<AiOutlineReload
												size={25}
												color="#fff"
												className="cursor-pointer rounded bg-green-600 p-1"
												onClick={() => handleRestoreDetection(item.IDDetection)}
											/>
										) : (
											<AiOutlineDelete
												size={25}
												color="#fff"
												className="cursor-pointer rounded bg-red-600 p-1"
												onClick={() => handleDeleteDetection(item.IDDetection)}
											/>
										)}
									</>
								)}
							</p>
						</div>
					))}

					{addDetection && !noLive ? (
						<div
							className={`mb-1 flex h-12 w-full flex-row items-center rounded-md bg-gray-100 py-1`}
						>
							<p className="w-2/12 px-1 font-mono">
								<select
									value={addDetection.ligne}
									className="h-10 w-full rounded-md border border-gray-300 focus:outline-none"
									onChange={(e) =>
										handleChangeAddDetection("ligne", parseInt(e.target.value))
									}
								>
									<option value=""></option>
									{EventLines.map((item: number) => (
										<option value={item} key={item}>
											{item}
										</option>
									))}
								</select>
							</p>
							<p className="w-4/12 px-1 font-mono"></p>
							<p className="w-4/12 px-1 font-mono">
								<input
									value={addDetection.date}
									className="h-10 w-full rounded-md border border-gray-300 focus:outline-none"
									step={0.1}
									onChange={(e) =>
										handleChangeAddDetection("date", e.target.value)
									}
									type="datetime-local"
								/>
							</p>
							<p className="flex w-2/12 flex-row justify-end gap-2 px-1 text-center font-mono">
								<AiOutlineCheck
									size={25}
									color="#fff"
									className="cursor-pointer rounded bg-green-600 p-1"
									onClick={handleAddDetection}
								/>
								<AiOutlineClose
									size={25}
									color="#fff"
									className="cursor-pointer rounded bg-red-600 p-1"
									onClick={() => setAddDetection(undefined)}
								/>
							</p>
						</div>
					) : (
						<>
							{noLive ? (
								<Tooltip
									className="text-md z-50 text-center"
									content={<Typography>Aucune prestation en cours</Typography>}
								>
									<button
										onClick={addRowDetection}
										className="ml-auto block cursor-not-allowed rounded bg-primary py-2 px-2 text-white opacity-50"
										disabled={noLive}
									>
										{trad[lang].addDetection}
									</button>
								</Tooltip>
							) : (
								<button
									onClick={addRowDetection}
									className="ml-auto block rounded bg-primary py-2 px-2 text-white"
									disabled={noLive}
								>
									{trad[lang].addDetection}
								</button>
							)}
						</>
					)}
				</div>

				<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
					<button
						type="button"
						className={`rounded-lg border border-primary bg-white px-5 py-2.5 text-center text-sm font-medium text-primary focus:outline-none ${
							isLoading ? "opacity-50" : ""
						}`}
						disabled={isLoading}
						onClick={close}
					>
						{trad[lang].close}
					</button>
				</div>
			</div>
		</div>
	);
};

export default HandleDetectionModal;
