import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiOutlineXMark } from "react-icons/hi2";
import { useQuery } from "react-query";
import { getEventRunsConfig, getSubscriptionOptions } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import { LiveContext } from "../../contexts/LiveContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";
import { ICountry } from "../../utils/CountriesList";
import Button from "../common/Button";
import DateField from "../common/DateField";
import NationalityField from "../common/NationalityField";
import SelectField from "../common/SelectField";
import TabsBtn from "../common/TabsBtn";
import TextField from "../common/TextField";
import RegistrationEditOption from "./RegistrationEditOption";
import { useParams } from "react-router-dom";
import { calculateAge } from "../../utils/DateFormater";

interface RegistrationEditModalProps {
	handleInputChange: (field: string, value: string, maths?: boolean) => void;
	handleDateChange: (field: string, value: string) => void;
	closePopup: () => void;
	onSaveClick: () => void;
	setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
	editedSubscription: any;
	conflict: boolean;
	canSave: boolean;
	birthdateError: boolean;

	ppsError: string;
	numLicenceError: string;

	selectedTab: string;
	editFormOptions: any;
	setEditFormOptions: React.Dispatch<React.SetStateAction<any>>;
}

const genderOptions = [
	{ key: "H", title: "H" },
	{ key: "F", title: "F" },
	{ key: "X", title: "X" }
];

const RegistrationEditModal = ({
	handleInputChange,
	handleDateChange,
	closePopup,
	onSaveClick,
	setSelectedTab,
	conflict,
	canSave,
	birthdateError,

	ppsError,
	numLicenceError,

	selectedTab,
	editedSubscription,
	editFormOptions,
	setEditFormOptions
}: RegistrationEditModalProps): JSX.Element => {
	const { lang } = useContext(AppContext);
	const { slug } = useParams();
	const [editForm, setEditForm] = useState<any>({});
	const { isFFAEvent } = useContext(LiveContext);
	const [birthdate, setBirthdate] = useState<string>("");
	const { isLoading: isLoadingSubOptions } = useQuery({
		queryKey: ["subscription_option", editedSubscription.id],
		queryFn: () => getSubscriptionOptions(editedSubscription.id),
		refetchOnWindowFocus: false
	});
	const phoneNumber =
		editedSubscription?.person?.phone ||
		JSON.parse(editedSubscription?.observations)?.infoPersonne?.telephone;
	const emergencyPhoneNumber =
		editedSubscription?.person?.emergency_phone ||
		JSON.parse(editedSubscription?.observations)?.infoPersonne?.emergency_phone;
	const { data: RunsInfos = [] } = useQuery({
		queryKey: ["runs_info", slug],
		queryFn: () => getEventRunsConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});
	const run = RunsInfos.find(
		(item: any) => item.id === editedSubscription?.calendrier?.id
	);
	const runnerAge = calculateAge(birthdate);
	const minAge = run?.prices[0]?.minAge;
	const maxAge = run?.prices[0]?.maxAge;
	const isMinAgeAvailable = runnerAge < minAge && minAge > 0;
	const isMaxAgeAvailable = runnerAge > maxAge && maxAge > 0;
	const isAgeUnavailable = isMinAgeAvailable || isMaxAgeAvailable;

	return (
		<>
			<Transition.Root show={editedSubscription !== null} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closePopup}
				>
					<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:h-screen sm:align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div
								className={classNames(
									selectedTab == "options"
										? "sm:max-w-xs md:max-w-sm lg:max-w-md"
										: "sm:max-w-xl md:max-w-2xl lg:max-w-4xl",
									"relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 sm:align-middle"
								)}
							>
								<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
										onClick={closePopup}
									>
										<span className="sr-only">Close</span>
										<HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>

								<div className="w-full flex-col items-start">
									<div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-center text-lg font-medium leading-6 text-gray-900"
										>
											{trad[lang].subEdit}
										</Dialog.Title>

										<div className="mt-4 flex flex-col items-center gap-2 overflow-visible pr-2">
											{isLoadingSubOptions && (
												<div className="flex items-center">
													{trad[lang].loading}
													<AiOutlineLoading3Quarters className="ml-2 animate-spin" />
												</div>
											)}

											{!isLoadingSubOptions && (
												<div className="w-full">
													{/* Tabs */}
													<div className="w-full pb-6">
														<TabsBtn
															selectedTab={selectedTab}
															setSelectedTab={setSelectedTab}
															tabs={[
																{
																	name: trad[lang].editGeneral,
																	value: "general"
																},
																{
																	name: trad[lang].subOptions,
																	value: "options"
																}
															]}
														/>
													</div>

													{selectedTab == "general" && (
														<div key="general">
															<div className="w-full text-center">
																{isMinAgeAvailable ? (
																	<p className="italic text-red-500">
																		*{trad[lang].minimmAge} {minAge}{" "}
																		{trad[lang].runnerAge}*
																	</p>
																) : null}
																{isMaxAgeAvailable ? (
																	<p className="italic text-red-500">
																		*{trad[lang].maximumAge} {maxAge}{" "}
																		{trad[lang].runnerAge}*
																	</p>
																) : null}
															</div>
															<div className="flex w-full flex-col pt-1 md:flex-row md:items-center md:gap-6">
																<TextField
																	id="lastname"
																	label={trad[lang].lastname}
																	value={editedSubscription.person?.lastname}
																	onChange={(e) =>
																		handleInputChange(
																			"lastname",
																			e.target.value
																		)
																	}
																	conflict={conflict}
																/>
																<TextField
																	id="firstname"
																	label={trad[lang].firstname}
																	value={editedSubscription.person?.firstname}
																	onChange={(e) =>
																		handleInputChange(
																			"firstname",
																			e.target.value
																		)
																	}
																	conflict={conflict}
																/>
															</div>
															<div className="flex w-full flex-col pt-3 md:flex-row md:items-center md:gap-6">
																<TextField
																	id="category"
																	disabled
																	label={"Catégorie"}
																	value={editedSubscription.categories[0]?.name}
																	conflict={conflict}
																/>
																<TextField
																	id="bib"
																	label={trad[lang].bib}
																	value={editedSubscription.bib}
																	onChange={(e) =>
																		handleInputChange(
																			"bib",
																			e.target.value,
																			true
																		)
																	}
																	min={1}
																	step={1}
																	conflict={conflict}
																/>
																<SelectField
																	id="sex"
																	label={trad[lang].gender}
																	value={editedSubscription.person?.sex?.toString()}
																	options={genderOptions}
																	onChange={(e: any) =>
																		handleInputChange("gender", e.target.value)
																	}
																/>
																<DateField
																	id="birthdate"
																	label={trad[lang].birthDate}
																	value={
																		editedSubscription.person?.birthdate?.split(
																			"T"
																		)[0]
																	}
																	error={birthdateError || isAgeUnavailable}
																	onChange={(e) => (
																		handleDateChange(
																			"birthdate",
																			e.target.value
																		),
																		setBirthdate(e.target.value)
																	)}
																/>
															</div>
															<div className="flex w-full flex-col pt-3 md:flex-row md:items-center md:gap-6">
																<TextField
																	id="email"
																	disabled={true}
																	label={trad[lang].email}
																	value={editedSubscription.person?.email}
																	onChange={(e) =>
																		handleInputChange("email", e.target.value)
																	}
																	conflict={conflict}
																/>
																<NationalityField
																	id="nationality"
																	defaultCountryCode={
																		editedSubscription?.person?.nationality
																	}
																	label={trad[lang].nationality}
																	value={editedSubscription.person?.nationality}
																	onChange={(country: ICountry) => {
																		setEditForm({
																			...editForm,
																			["nationality"]: country?.code || ""
																		});
																	}}
																	lang={lang}
																/>
															</div>
															<div className="flex w-full flex-col pt-3 md:flex-row md:items-center md:gap-6">
																<NationalityField
																	id="country"
																	defaultCountryCode={
																		editedSubscription?.person?.country
																	}
																	label={trad[lang].country}
																	value={editedSubscription.person?.country}
																	onChange={(country: ICountry) => {
																		setEditForm({
																			...editForm,
																			["country"]:
																				country?.idWindev?.toString() || ""
																		});
																	}}
																	lang={lang}
																/>
																<TextField
																	id="address1"
																	label={trad[lang].address + " 1"}
																	value={editedSubscription.person?.adresse1}
																	onChange={(e) =>
																		handleInputChange(
																			"address1",
																			e.target.value
																		)
																	}
																	conflict={conflict}
																/>
															</div>
															<div className="flex w-full flex-col pt-3 md:flex-row md:items-center md:gap-6">
																<TextField
																	id="address2"
																	label={trad[lang].address + " 2"}
																	value={editedSubscription.person?.adresse2}
																	onChange={(e) =>
																		handleInputChange(
																			"address2",
																			e.target.value
																		)
																	}
																	conflict={conflict}
																/>
																<TextField
																	id="address3"
																	label={trad[lang].address + " 3"}
																	value={editedSubscription.person?.adresse3}
																	onChange={(e) =>
																		handleInputChange(
																			"address3",
																			e.target.value
																		)
																	}
																	conflict={conflict}
																/>
															</div>
															<div className="flex w-full flex-col pt-3 md:flex-row md:items-center md:gap-6">
																<TextField
																	id="city"
																	label={trad[lang].city_label}
																	value={editedSubscription.person?.city}
																	onChange={(e) =>
																		handleInputChange("city", e.target.value)
																	}
																	conflict={conflict}
																/>
																<TextField
																	id="postal_code"
																	label={trad[lang].zipCode}
																	value={editedSubscription.person?.postal_code}
																	onChange={(e) =>
																		handleInputChange(
																			"postal_code",
																			e.target.value
																		)
																	}
																	conflict={conflict}
																/>
															</div>
															<div className="flex w-full flex-col pt-3 md:flex-row md:items-center md:gap-6">
																<TextField
																	id="phone"
																	label={trad[lang].phone}
																	value={phoneNumber}
																	onChange={(e) =>
																		handleInputChange("phone", e.target.value)
																	}
																	conflict={conflict}
																/>
																<TextField
																	id="emergency_phone"
																	label={trad[lang].emergency_phone}
																	value={emergencyPhoneNumber}
																	onChange={(e) =>
																		handleInputChange(
																			"emergency_phone",
																			e.target.value
																		)
																	}
																	conflict={conflict}
																/>
															</div>
															{isFFAEvent && (
																<div className="flex w-full flex-col pt-3 md:flex-row md:items-center md:gap-6">
																	<TextField
																		id="numLicence"
																		label={
																			trad[lang].event_field_numLicence_label
																		}
																		value={
																			JSON.parse(
																				editedSubscription.observations
																			)?.infoPersonne?.numLicence || ""
																		}
																		hasError={numLicenceError?.length > 0}
																		errorMessage={numLicenceError}
																		onChange={(e) =>
																			handleInputChange(
																				"numLicence",
																				e.target.value
																			)
																		}
																		conflict={conflict}
																	/>
																	<TextField
																		id="pps"
																		label="Numéro Prévention Parcours Santé FFA"
																		value={
																			JSON.parse(
																				editedSubscription?.observations
																			)?.infoPersonne?.pps || ""
																		}
																		hasError={ppsError?.length > 0}
																		errorMessage={ppsError}
																		onChange={(e) =>
																			handleInputChange(
																				"pps",
																				e.target.value
																			)
																		}
																		conflict={conflict}
																	/>
																</div>
															)}
															<div className="flex w-full flex-col pt-3 md:flex-row md:items-center md:gap-6">
																<TextField
																	id="club"
																	label={trad[lang].event_field_club_label}
																	value={
																		JSON.parse(editedSubscription.observations)
																			?.infoPersonne?.club || ""
																	}
																	onChange={(e) =>
																		handleInputChange("club", e.target.value)
																	}
																/>
															</div>
														</div>
													)}

													{selectedTab == "options" && (
														<RegistrationEditOption
															editedSubscription={editedSubscription}
															editFormOptions={editFormOptions}
															setEditFormOptions={setEditFormOptions}
														/>
													)}
												</div>
											)}
										</div>
										<div className="mt-16 sm:mt-14 sm:flex sm:flex-row-reverse">
											<Button
												buttonType="button"
												disabled={!canSave || isAgeUnavailable}
												title={trad[lang].save}
												onClick={onSaveClick}
											/>
											<Button
												buttonType="button"
												title={trad[lang].cancel}
												onClick={closePopup}
												light={true}
											/>
										</div>
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

export default RegistrationEditModal;
