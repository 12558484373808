import { useContext, useMemo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { refundSubscription } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";
import Toast from "../../utils/Toasts";

const RegistrationsRefundTable = ({
	rows,
	refund,
	refetch
}: {
	rows: any;
	refund: any;
	refetch: any;
}) => {
	const { lang } = useContext(AppContext);
	const { slug, idInscription } = useParams();
	const navigate = useNavigate();
	const [refundStatus, setRefundStatus] = useState<{
		[key: string]: { checked: boolean; amount: string; isFees: boolean };
	}>({});
	const [refundReason, setRefundReason] = useState("");
	const [orgaSubscriptionFees, setOrgaSubscriptionFees] = useState<
		boolean | null
	>();
	const [orgaRefundFees, setOrgaRefundFees] = useState<boolean | null>();
	const [showRefundSummary, setShowRefundSummary] = useState(false);
	const [cancelSubscription, setCancelSubscription] = useState(true);
	const [loading, setLoading] = useState(false);
    const MIN_REFUND_AMOUNT = 0;

	const handleChecked = (ligne_commande: any) => {
		const { IDLigneCommande, PrixTTC, produit } = ligne_commande;
		const oldValue = refundStatus[IDLigneCommande.toString()] || {
			checked: false,
			amount: parseFloat(PrixTTC),
			isFees: !produit ? true : false
		};

		oldValue.checked = !oldValue.checked;

		if (oldValue.isFees) {
			setOrgaSubscriptionFees(oldValue.checked);
		}

		setRefundStatus((old) => ({
			...old,
			[IDLigneCommande]: oldValue
		}));
	};

	const handleInput = (ligne_commande: any, value: string) => {
		const { IDLigneCommande, PrixTTC, produit } = ligne_commande;
		const oldValue = refundStatus[IDLigneCommande.toString()] || {
			checked: false,
			amount: PrixTTC,
			isFees: !produit ? true : false
		};

		const floatValue = parseFloat(value);

		if (value == "" || (floatValue >= 0 && floatValue <= parseFloat(PrixTTC))) {
			oldValue.amount = value;
		} else if (floatValue < 0) {
			oldValue.amount = "0";
		} else {
			oldValue.amount = parseFloat(PrixTTC).toFixed(2);
		}

		setRefundStatus((old) => ({
			...old,
			[IDLigneCommande]: oldValue
		}));
	};

	const validRefund = useMemo(() => {
		const validAmounts = rows.filter(
			(item: any) =>
				refundStatus[item.IDLigneCommande]?.checked &&
				refundStatus[item.IDLigneCommande]?.amount <= item.PrixTTC &&
				parseFloat(refundStatus[item.IDLigneCommande]?.amount) > MIN_REFUND_AMOUNT
		);

		const validFees = orgaSubscriptionFees != null && orgaRefundFees != null;

		return validAmounts.length > 0 && validFees;
	}, [refundStatus, orgaSubscriptionFees, orgaRefundFees]);

	const refundAmount = useMemo(() => {
		let rows_checked = Object.values(refundStatus).filter(
			(item) => item.checked
		);

		const rows_amount = rows_checked.reduce(
			(acc, item) => acc + parseFloat(item.amount || "0"),
			0
		);

		return orgaRefundFees ? rows_amount : rows_amount - 1;
	}, [refundStatus, orgaSubscriptionFees, orgaRefundFees]);

	const handleOrgaFees = (value: boolean) => {
		setOrgaSubscriptionFees(value);

		const fees = rows.filter((item: any) => item.produit == null);

		if (fees.length == 0) return;

		const { IDLigneCommande, PrixTTC, produit } = fees[0];

		const oldValue = refundStatus[IDLigneCommande.toString()] || {
			checked: false,
			amount: parseFloat(PrixTTC),
			isFees: !produit ? true : false
		};

		oldValue.checked = value;

		setRefundStatus((old) => ({
			...old,
			[IDLigneCommande]: oldValue
		}));
	};

	const confirmRefund = async () => {
		try {
			if (loading) return;
			setLoading(true);
			if (!idInscription || orgaRefundFees == null)
				throw new Error("[getOrderRows error]");

			const body = {
				refund: refundStatus,
				orgaRefundFees,
				refundReason,
				cancelSubscription
			};

			await refundSubscription(idInscription, body);

			await refetch();

			Toast.success(trad[lang].refundSuccess);
		} catch (error) {
			Toast.error(trad[lang].refundError);
		} finally {
			setLoading(false);
		}
	};

	if (refund != null) {
		return (
			<p className="text-center">
				{trad[lang].already_refunded(
					Math.abs(refund.TotalTTC).toFixed(2),
					new Date(refund.CommandeLe).toLocaleString("fr-FR")
				)}
			</p>
		);
	}

	return (
		<>
			<div className="w-[95%] py-2 align-middle">
				<div className="overflow-x-auto border border-gray-200">
					<table className="w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th
									scope="col"
									className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
								></th>
								<th
									scope="col"
									className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
								>
									{trad[lang].product_name}
								</th>
								<th
									scope="col"
									className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
								>
									{trad[lang].refund_price}
								</th>
								<th
									scope="col"
									className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
								>
									{trad[lang].refund_amount}
								</th>
							</tr>
						</thead>

						<tbody className="divide-y divide-gray-200 bg-white">
							{rows.map((item: any) => (
								<tr
									className="cursor-pointer transition-colors hover:bg-gray-50"
									key={item.IDLigneCommande}
									onClick={() => handleChecked(item)}
								>
									<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
										<input
											type="checkbox"
											className="form-checkbox rounded-sm focus:ring-transparent"
											checked={
												refundStatus[item.IDLigneCommande]?.checked || false
											}
											readOnly
										/>
									</td>
									<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
										{item?.produit
											? `${item.produit.DesignationInternet} - ${item.personne.lastname} ${item.personne.firstname}`
											: trad[lang].subscription_fees}
									</td>
									<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">{`${parseFloat(
										item.PrixTTC
									).toFixed(2)} €`}</td>
									<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
										<input
											type="text"
											className="form-input w-full rounded-md"
											inputMode="numeric"
											onChange={(e) => handleInput(item, e.target.value)}
											value={refundStatus[item.IDLigneCommande]?.amount}
											onClick={(e) => e.stopPropagation()}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<textarea
					className="form-textarea my-4 w-full rounded-md"
					placeholder={trad[lang].refund_reason}
					inputMode="numeric"
					onChange={(e) => setRefundReason(e.target.value)}
					value={refundReason}
				/>

				<div className="mb-6 flex flex-col gap-2">
					<p className="">{trad[lang].orga_sub_fees}</p>

					<div className="flex gap-8">
						<div
							className="flex items-center gap-1"
							onClick={() => handleOrgaFees(true)}
						>
							<input
								type="radio"
								className="form-radio"
								id="orga_sub_fees_true"
								name="orga_sub_fees"
								checked={orgaSubscriptionFees == true}
								readOnly
							/>
							<label htmlFor="orga_sub_fees_true">{trad[lang].yes}</label>
						</div>

						<div
							className="flex items-center gap-1"
							onClick={() => handleOrgaFees(false)}
						>
							<input
								type="radio"
								className="form-radio"
								id="orga_sub_fees_false"
								name="orga_sub_fees"
								checked={orgaSubscriptionFees == false}
								readOnly
							/>
							<label htmlFor="orga_sub_fees_false">{trad[lang].no}</label>
						</div>
					</div>
				</div>

				<div className="mb-4 flex flex-col gap-2">
					<p className="">{trad[lang].orga_refund_fees}</p>

					<div className="flex gap-8">
						<div
							className="flex items-center gap-1"
							onClick={() => setOrgaRefundFees(true)}
						>
							<input
								type="radio"
								className="form-radio"
								id="orga_refund_fees_true"
								name="orga_refund_fees"
								checked={orgaRefundFees == true}
								readOnly
							/>
							<label htmlFor="orga_refund_fees_true">{trad[lang].yes}</label>
						</div>

						<div
							className="flex items-center gap-1"
							onClick={() => setOrgaRefundFees(false)}
						>
							<input
								type="radio"
								className="form-radio"
								id="orga_refund_fees_false"
								name="orga_refund_fees"
								checked={orgaRefundFees == false}
								readOnly
							/>
							<label htmlFor="orga_refund_fees_false">{trad[lang].no}</label>
						</div>
					</div>
				</div>

				<div className="mb-4 flex flex-col gap-2">
					<p className="">{trad[lang].invalidateSub}</p>

					<div className="flex gap-8">
						<div
							className="flex items-center gap-1"
							onClick={() => setCancelSubscription(true)}
						>
							<input
								type="radio"
								className="form-radio"
								id="invalidate_sub_true"
								name="invalidate_sub"
								checked={cancelSubscription == true}
								readOnly
							/>
							<label htmlFor="invalidate_sub_true">{trad[lang].yes}</label>
						</div>

						<div
							className="flex items-center gap-1"
							onClick={() => setCancelSubscription(false)}
						>
							<input
								type="radio"
								className="form-radio"
								id="invalidate_sub_false"
								name="invalidate_sub"
								checked={cancelSubscription == false}
								readOnly
							/>
							<label htmlFor="invalidate_sub_false">{trad[lang].no}</label>
						</div>
					</div>
				</div>

				<p className="mb-4 text-red-500">{trad[lang].contact_doubt}</p>

				<div className="mb-4 flex w-fit flex-col gap-3 rounded-md border border-gray-300 bg-gray-100 p-4">
					<p>
						{trad[lang].refund_paid}{" "}
						<span className="font-bold">
							{rows
								.reduce(
									(acc: number, item: any) => acc + parseFloat(item.PrixTTC),
									0
								)
								.toFixed(2)}
							€
						</span>
					</p>
					<p>
						{trad[lang].amount_to_refund}{" "}
						<span className="font-bold">{refundAmount.toFixed(2)} €</span>
					</p>
				</div>

				<button
					type="button"
					className={classNames(
						"block rounded-lg  px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-0",
						validRefund && refundAmount > MIN_REFUND_AMOUNT
							? "bg-primary hover:bg-primarydark"
							: "cursor-default bg-gray-300"
					)}
					onClick={() => setShowRefundSummary(true)}
					disabled={!validRefund || refundAmount <= MIN_REFUND_AMOUNT}
				>
					{trad[lang].refund}
				</button>
			</div>

			{showRefundSummary && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
					<div className="max-h-[90vh] max-w-screen-lg rounded-md border bg-white">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h3 className="text-xl font-semibold text-gray-900">
								{trad[lang].refund_summary}
							</h3>

							<button
								type="button"
								className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
								onClick={() => setShowRefundSummary(false)}
							>
								<AiOutlineClose size={16} />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="max-h-[300px] overflow-y-auto overflow-x-hidden px-4 py-6">
							<p>
								<span>{trad[lang].refund_summary_start_text}</span>
								<span className="font-bold">{refundAmount.toFixed(2)} €</span>
								<span>{trad[lang].refund_summary_end_text}</span>
							</p>
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								type="button"
								className={classNames(
									"block rounded-lg  px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-0",
									refundAmount > MIN_REFUND_AMOUNT
										? "bg-primary hover:bg-primarydark"
										: "cursor-default bg-gray-300"
								)}
								onClick={confirmRefund}
								disabled={refundAmount <= MIN_REFUND_AMOUNT || loading}
							>
								{trad[lang].confirm_refund}
							</button>

							<button
								type="button"
								className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
								onClick={() => setShowRefundSummary(false)}
							>
								{trad[lang].refund_close_summary}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default RegistrationsRefundTable;
